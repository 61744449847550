var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offer-show" },
    [
      _c("div", { staticClass: "offer-show__title" }, [
        _c("h1", { class: _vm.titleClass }, [_vm._v(_vm._s(_vm.title))]),
        _c("h2", { staticClass: "offer-show__title-subtitle" }, [
          _vm._v(_vm._s(_vm.subtitle))
        ])
      ]),
      _c(
        "div",
        { staticClass: "offer-show__offers", style: _vm.widthStyle },
        [
          _c(
            "v-slide-group",
            {
              staticClass: "offer-show__offers-group",
              attrs: { "show-arrows": !_vm.$vuetify.breakpoint.smAndDown },
              model: {
                value: _vm.list,
                callback: function($$v) {
                  _vm.list = $$v
                },
                expression: "list"
              }
            },
            _vm._l(_vm.list, function(item, $index) {
              return _c(
                "v-slide-item",
                {
                  key: $index,
                  staticClass: "offer-show__offers-card",
                  attrs: { "data-num": $index + 1 }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "offer-show__offers-card__card",
                      on: {
                        click: function($event) {
                          return _vm.onOfferCardClick(item.id)
                        }
                      }
                    },
                    [
                      _c("OfferCard", {
                        attrs: {
                          companyLogoUrl: item.companyAvatar,
                          companyName: item.companyName,
                          filledPoints: item.filledPoints || 0,
                          imageUrl: item.previewPhotoUrl,
                          maxPoints: item.maxPoints,
                          offerTitle: item.title,
                          offerTypeId: item.offerTypeId,
                          points: item.points,
                          targetAudienceId: item.targetAudienceId,
                          drawAt: item.drawAt,
                          offerId: item.id
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "offer-show__btn",
          attrs: {
            color: "homeoffers_fond_bouton",
            large: "",
            rounded: "",
            to: "/shop"
          }
        },
        [_vm._v(" " + _vm._s(_vm.catchphrase) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }