






















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';
import Countdown from '@/components/Offer/Countdown.vue';
import OrdersNumber from '@/components/Offer/OrdersNumber.vue';
import SvgPictoLuck from '@/components/Svg/SvgPictoLuck.vue';
import SvgPictoPro from '@/components/Svg/SvgPictoPro.vue';
import SvgPictoShowcase from '@/components/Svg/SvgPictoShowcase.vue';
import SvgPictoCollect from '@/components/Svg/SvgPictoCollect.vue';
import SvgPictoCashback from '@/components/Svg/SvgPictoCashback.vue';

import * as OfferService from '@/services/merchantSpace/OfferService';

@Component({
  components: {
    OfferAvatar,
    Countdown,
    OrdersNumber,
    SvgPictoLuck,
    SvgPictoPro,
    SvgPictoShowcase,
    SvgPictoCollect,
    SvgPictoCashback,
  },
})
export default class OfferCard extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
  })
  readonly imageUrl!: string;

  @Prop({
    type: String,
    required: true,
    default: 'Lorem ipsum',
  })
  readonly companyName!: string;

  @Prop({
    type: String,
    required: true,
    default: '              ',
  })
  readonly offerTitle!: string;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  readonly points!: number;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  readonly filledPoints!: number;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  readonly maxPoints!: number;

  @Prop({
    type: Date,
    required: false,
    default: null,
  })
  readonly drawAt!: Date;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  readonly offerId!: Number;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  readonly targetAudienceId!: number;

  @Prop({
    type: Number,
    required: true,
    default: '',
  })
  readonly offerTypeId!: number;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly companyLogoUrl!: string;

  textPts = '';

  pictoPro: string = require('@/assets/picto/pro.svg');

  get companyInitials() {
    return this.companyName.charAt(0).toUpperCase();
  }

  get compTargetAudience(): string | null {
    return OfferService.isPublicOffer(this.targetAudienceId) ? null : this.pictoPro;
  }

  get compOfferType() {
    return OfferService.getOfferType(this.offerTypeId);
  }

  get isLuck() {
    return this.compOfferType === OfferService.offerTypeEnum.LUCK.type;
  }

  get isCashback() {
    return this.compOfferType === OfferService.offerTypeEnum.CASHBACK.type;
  }

  get isShowcase() {
    return this.compOfferType === OfferService.offerTypeEnum.SHOWCASE.type;
  }

  get isCollect() {
    return this.compOfferType === OfferService.offerTypeEnum.COLLECT.type;
  }

  get compImage() {
    return `${process.env.VUE_APP_API_URL}/api${this.imageUrl}`;
  }

  get progressBarValue() {
    return (100 * this.filledPoints) / this.maxPoints;
  }

  get textPt() {
    if (this.filledPoints < 1) {
      this.textPts = 'pt';
    } else {
      this.textPts = 'pts';
    }
    return this.textPts;
  }

  get platformId() {
    return this.$store.getters['platform/getPlatform'].id;
  }
}
