var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "back-navigation", on: { click: _vm.backNavigation } },
    [
      _c(
        "v-icon",
        {
          staticClass: "back-navigation__icon",
          attrs: { "aria-label": "Back Navigation", "aria-hidden": "false" }
        },
        [_vm._v(" fas fa-chevron-left ")]
      ),
      _c("span", [_vm._v(_vm._s(_vm.title))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }