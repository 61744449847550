












































import { Component, Vue } from 'vue-property-decorator';
import * as Cookie from 'js-cookie';
import EmailConfirmationBanner from '@/components/Home/EmailConfirmationBanner.vue';
import PendingValidationBanner from '@/components/Home/PendingValidationBanner.vue';
import SynchronizationProposalBanner from './SynchronizationProposalBanner.vue';
import { BannerInterface } from '@/services/merchantSpace/PlatformService';
import * as common from '@/services/common/common';
import { getTranslation } from '@/i18n';
import { ServiceKey } from '@/constants';
import { SSOBehavior } from '@/services/merchantSpace/types';


@Component({
  components: { EmailConfirmationBanner, PendingValidationBanner, SynchronizationProposalBanner },
})
export default class Banner extends Vue {
  condition = false;

  get showCustom(): boolean {
    const retrievedCookie = Cookie.get('BannerDismissed');

    if (retrievedCookie) {
      const retrievedBannerInfos = retrievedCookie.split(' | ');
      const retrievedBannerType = retrievedBannerInfos[0];
      const retrievedBannerText = retrievedBannerInfos[1];

      return (
        this.isConnected && this.banner.bannerActivated && (retrievedBannerType !== this.banner.bannerType || retrievedBannerText !== this.banner.bannerText)
      );
    }

    return this.isConnected && this.banner.bannerActivated && !retrievedCookie;
  }

  set showCustom(value: boolean) {
    const cookieValue = `${this.banner.bannerType} | ${this.banner.bannerText}`;
    Cookie.set('BannerDismissed', cookieValue, {
      expires: 31,
      sameSite: 'strict',
    });
  }

  get showEmailBanner(): boolean {
    return this.isConnected && !this.$store.getters['user/getConfirmationEmail'];
  }

  get showPendingBanner(): boolean {
    return this.banner.postSignupBannerActivated;
  }

  get banner(): BannerInterface {
    return this.$store.getters['platform/getBannerInfos'];
  }

  get textCols() {
    return this.isMobile ? 12 : 10;
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get serviceLabel() {
    return getTranslation(`${this.platform.key}.signInForm.connectWith`) || getTranslation(`signInForm.connectWith`);
  }

  get canAssignSSO() {
    if(!this.isConnected) {
      return false;
    }
    const ssoBehavior = this.$store.getters['platform/ssoBehavior'];
    let canAssign = ssoBehavior === SSOBehavior.WITH_CLASSIC;
    if(canAssign) {
      const currentUserServices = this.$store.getters['user/getUser'].userServices;
      const astuceUserService = currentUserServices.find(
        userService => userService.service.key === ServiceKey.MY_ASTUCE
      );
      canAssign = !astuceUserService;
    }
    return canAssign;
  }

  get bannerStyle() {
    return this.condition ? { marginTop: '3rem' } : {};
  }
}
