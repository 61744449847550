export const FORCE_SIGNIN_REDIRECT = 'signin:redirect';

export default {
  save(key, value: object) {
    return localStorage.setItem(key, JSON.stringify(value));
  },

  get(key) {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  },

  remove(key) {
    localStorage.removeItem(key);
  },

  pull(key) {
    const result = this.get(key);
    if (result) {
      this.remove(key);
    }

    return result;
  },
};
