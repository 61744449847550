import '@/assets/scss/_styles.scss';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import InfiniteLoading from 'vue-infinite-loading';
import VueTheMask from 'vue-the-mask';
import VueClipboard from 'vue-clipboard2';
import VueHead from 'vue-head';
import VueMeta from 'vue-meta';
import VueMatomo from 'vue-matomo';
import router from '@/router';
import store from '@/store';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { isDevEnv } from './services/API';
import { setRouter } from './libs/singleton';
import i18n from './i18n/index';

dayjs.extend(utc);

setRouter(router);

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(VueClipboard);
Vue.use(VueHead);
Vue.use(InfiniteLoading);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

if (process.env.VUE_APP_MATOMO_URL && process.env.VUE_APP_MATOMO_ID) {
  // doc : https://github.com/AmazingDreams/vue-matomo
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_URL,
    siteId: process.env.VUE_APP_MATOMO_ID,
    router,
  });
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message && error.message === 'Network Error' && isDevEnv()) {
      error.response = { message: 'API Not Online', config: { headers: { ignoreToast: false } } };
      return Promise.reject(error);
    }
    return error;
  },
);

/**
 * Interceptor to display error message on server errors
 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && !error.response.config.headers.ignoreToast) {
      await store.dispatch('user/showError', error.response.message);
      return error;
    }
    return error;
  },
);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
