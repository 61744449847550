export default function joinPath(...args: string[]) {
  return args
    .map((path) => {
      let s = path;
      if (s[0] === '/') {
        s = s.slice(1);
      }

      if (s[s.length - 1] === '/') {
        s = s.slice(0, s.length - 1);
      }

      return s.trim();
    })
    .join('/');
}
