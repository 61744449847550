var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "filter", attrs: { "data-cy": "shop-filters-container" } },
    [
      _c(
        "v-card-title",
        { staticClass: "filter-title" },
        [
          _c("h2", { staticClass: "filter-title-text" }, [_vm._v("Trier")]),
          _c(
            "v-container",
            { staticClass: "filter-radio" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "filter-radio",
                  attrs: { "hide-details": "true" },
                  model: {
                    value: _vm.sortMode,
                    callback: function($$v) {
                      _vm.sortMode = $$v
                    },
                    expression: "sortMode"
                  }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "filter-radio__line" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "filter-radio-col" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "0",
                              "input-value": "true",
                              color: "actif",
                              "data-cy": "shop-filter-order-new-to-old"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "filter-radio-label" },
                                      [_vm._v("+ RÉCENT AU - RÉCENT")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "filter-radio-col" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "1",
                              color: "actif",
                              "data-cy": "shop-filter-order-old-to-new"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "filter-radio-label" },
                                      [_vm._v("- RÉCENT AU + RÉCENT")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "filter-radio-col" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "2",
                              color: "actif",
                              "data-cy": "shop-filter-order-expensive-to-cheap"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "filter-radio-label" },
                                      [_vm._v("POINTS CROISSANTS")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "filter-radio-col" },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "3",
                              color: "actif",
                              "data-cy": "shop-filter-order-cheap-to-expensive"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "filter-radio-label" },
                                      [_vm._v("POINTS DÉCROISSANTS")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.$vuetify.breakpoint.smAndUp ? _c("v-divider") : _vm._e(),
      _c("v-card-title", { staticClass: "filter-title" }, [
        _c("h2", { staticClass: "filter-title-text" }, [_vm._v("Filtrer")])
      ]),
      _vm.events.length
        ? [
            _c("v-card-title", { staticClass: "filter-title-small" }, [
              _c("div", { staticClass: "filter-title-small-text" }, [
                _vm._v("Événements")
              ])
            ]),
            _c(
              "div",
              { staticClass: "filter-merchants" },
              [
                _c("v-virtual-scroll", {
                  staticClass: "filter-merchants-scroller",
                  attrs: {
                    items: _vm.events,
                    height:
                      (_vm.events.length + 1) * 40 > 350
                        ? 350
                        : (_vm.events.length + 1) * 40,
                    "item-height": "40"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c("v-checkbox", {
                                  staticClass: "filter-merchants-checkbox",
                                  attrs: {
                                    color: "actif",
                                    value: item.id,
                                    "hide-details": "true",
                                    label: item.name
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.checkboxChange(item.id)
                                    }
                                  },
                                  model: {
                                    value: _vm.eventList,
                                    callback: function($$v) {
                                      _vm.eventList = $$v
                                    },
                                    expression: "eventList"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2548561119
                  )
                })
              ],
              1
            ),
            _c("v-divider")
          ]
        : _vm._e(),
      _c(
        "v-card-title",
        {
          staticClass: "filter-title-small",
          attrs: { "data-cy": "shop-filter-points-title" }
        },
        [
          _c("div", { staticClass: "filter-title-small-text" }, [
            _vm._v("Nombre de points")
          ])
        ]
      ),
      _c("v-range-slider", {
        staticClass: "filter-slider",
        attrs: {
          step: "100",
          min: _vm.internalMin,
          max: _vm.internalMax,
          "thumb-label": "always",
          "thumb-size": "36",
          "thumb-color": "shopfiltre_fond",
          color: "shopfiltre_texte",
          "track-color": "inactif",
          "track-fill-color": "actif",
          "hide-details": "true",
          "data-cy": "shop-filter-slider"
        },
        on: { change: _vm.filterPointsChange },
        model: {
          value: _vm.filterPoints,
          callback: function($$v) {
            _vm.filterPoints = $$v
          },
          expression: "filterPoints"
        }
      }),
      _c("v-divider"),
      _c(
        "v-card-title",
        {
          staticClass: "filter-title-small",
          attrs: { "data-cy": "shop-filter-localisation-title" },
          on: { click: _vm.triggerGeoloc }
        },
        [
          _c("div", { staticClass: "filter-title-small-text" }, [
            _vm._v("Localisation")
          ]),
          !_vm.displayGeoloc
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-plus") }
              })
            : _vm._e(),
          _vm.displayGeoloc
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-minus") }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.displayGeoloc
        ? _c(
            "div",
            { attrs: { "data-cy": "shop-filter-localisation-menu" } },
            [
              _c("v-autocomplete", {
                staticClass: "filter-geo_address",
                attrs: {
                  "item-text": "value.label",
                  "item-value": "value",
                  items: _vm.geoItems,
                  "search-input": _vm.addressSearch,
                  placeholder: "VILLE OU CODE POSTAL",
                  "append-icon": "mdi-magnify",
                  "hide-no-data": "",
                  "hide-selected": "",
                  "no-filter": "",
                  filter: function() {
                    return true
                  },
                  multiple: "",
                  chips: "",
                  color: "actif",
                  "item-color": "shopfiltre_texte",
                  readonly:
                    _vm.displayedAddresses && _vm.displayedAddresses.length >= 5
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.addressSearch = $event
                  },
                  "update:search-input": function($event) {
                    _vm.addressSearch = $event
                  },
                  change: function($event) {
                    _vm.addressSearch = null
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function(data) {
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "filter-geo_address-chip",
                              attrs: { color: "actif", close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFromAddresses(data.item)
                                }
                              }
                            },
                            [
                              data.item.label
                                ? [_vm._v(_vm._s(data.item.label))]
                                : _vm._e(),
                              data.item.value
                                ? [_vm._v(_vm._s(data.item.value.label))]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      }
                    },
                    {
                      key: "item",
                      fn: function(data) {
                        return [
                          _vm._v(" " + _vm._s(data.item.value.label) + " ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1775803313
                ),
                model: {
                  value: _vm.displayedAddresses,
                  callback: function($$v) {
                    _vm.displayedAddresses = $$v
                  },
                  expression: "displayedAddresses"
                }
              }),
              _vm.displayedAddresses.length <= 1
                ? _c("v-slider", {
                    staticClass: "filter-geo_address-area",
                    attrs: {
                      "thumb-color": "shopfiltre_fond",
                      color: "shopfiltre_texte",
                      "track-color": "inactif",
                      min: "0",
                      max: "9",
                      "thumb-size": "42",
                      "tick-size": "0",
                      "thumb-label": "always",
                      "tick-labels": _vm.ticksAreaLabels,
                      label: "Dans un rayon autour de"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "thumb-label",
                          fn: function(ref) {
                            var value = ref.value
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.filterArea[value]) + " km "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1255814304
                    ),
                    model: {
                      value: _vm.selectedArea,
                      callback: function($$v) {
                        _vm.selectedArea = $$v
                      },
                      expression: "selectedArea"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-card-title",
        {
          staticClass: "filter-title-small",
          attrs: { "data-cy": "shop-filter-categories-title" },
          on: { click: _vm.triggerCategories }
        },
        [
          _c("div", { staticClass: "filter-title-small-text" }, [
            _vm._v("Catégories")
          ]),
          !_vm.displayCategories
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-plus") }
              })
            : _vm._e(),
          _vm.displayCategories
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-minus") }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.displayCategories
        ? _c(
            "div",
            {
              staticClass: "filter-merchants",
              attrs: { "data-cy": "shop-filter-categories-menu" }
            },
            [
              _c("v-virtual-scroll", {
                attrs: {
                  items: _vm.sortedCategoriesList,
                  height:
                    (_vm.sortedCategoriesList.length + 1) * 40 > 350
                      ? 350
                      : (_vm.sortedCategoriesList.length + 1) * 40,
                  "item-height": "40",
                  "data-cy": "shop-filter-categories-virtual-scroll"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-list-item",
                            [
                              _c("v-checkbox", {
                                staticClass: "filter-merchants-checkbox",
                                attrs: {
                                  color: "actif",
                                  value: item.id,
                                  "hide-details": "true",
                                  label:
                                    item.offerCount === 1
                                      ? item.name +
                                        " (" +
                                        item.offerCount +
                                        " offre)"
                                      : item.name +
                                        " (" +
                                        item.offerCount +
                                        " offres)"
                                },
                                model: {
                                  value: _vm.categoriesList,
                                  callback: function($$v) {
                                    _vm.categoriesList = $$v
                                  },
                                  expression: "categoriesList"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2883956491
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-card-title",
        {
          staticClass: "filter-title-small",
          attrs: { "data-cy": "shop-filter-types-title" },
          on: { click: _vm.triggerOfferTypes }
        },
        [
          _c("div", { staticClass: "filter-title-small-text" }, [
            _vm._v("Type d'offres")
          ]),
          !_vm.displayOfferTypes
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-plus") }
              })
            : _vm._e(),
          _vm.displayOfferTypes
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-minus") }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.displayOfferTypes
        ? _c(
            "div",
            { attrs: { "data-cy": "shop-filter-types-menu" } },
            [
              _c(
                "v-container",
                {
                  staticClass: "filter-offertypes",
                  attrs: { "data-cy": "shop-filter-types-container" }
                },
                _vm._l(_vm.offerTypesToDisplay, function(offerType) {
                  return _c(
                    "div",
                    {
                      key: offerType.id,
                      staticClass: "filter-offertypes-icon",
                      class: _vm.isSelected(
                        _vm.offerTypeList.find(function(e) {
                          return e.id === offerType.id
                        }).isSelected
                      ),
                      attrs: { "data-cy": "shop-filter-types-" + offerType.id },
                      on: {
                        click: function($event) {
                          return _vm.toggleOfferType(offerType.id)
                        }
                      }
                    },
                    [
                      _c(offerType.icon, { tag: "component" }),
                      _c("div", { staticClass: "filter-offertypes-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.offerTypeList.find(function(e) {
                              return e.id === offerType.id
                            }).name
                          )
                        )
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-card-title",
        {
          staticClass: "filter-title-small",
          attrs: { "data-cy": "shop-filter-merchants-title" },
          on: { click: _vm.triggerMerchant }
        },
        [
          _c("div", { staticClass: "filter-title-small-text" }, [
            _vm._v("Marchands")
          ]),
          !_vm.displayMerchants
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-plus") }
              })
            : _vm._e(),
          _vm.displayMerchants
            ? _c("v-icon", {
                staticClass: "filter-title-small-icon",
                attrs: { medium: "" },
                domProps: { textContent: _vm._s("fas fa-minus") }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.displayMerchants
        ? _c(
            "div",
            {
              staticClass: "filter-merchants",
              attrs: { "data-cy": "shop-filter-merchants-menu" }
            },
            [
              _c("v-virtual-scroll", {
                staticClass: "filter-merchants-scroller",
                attrs: {
                  items: _vm.sortedMerchantsList,
                  height:
                    (_vm.sortedMerchantsList.length + 1) * 40 > 350
                      ? 350
                      : (_vm.sortedMerchantsList.length + 1) * 40,
                  "item-height": "40",
                  "data-cy": "shop-filter-merchants-virtual-scroll"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-list-item",
                            [
                              _c("v-checkbox", {
                                staticClass: "filter-merchants-checkbox",
                                attrs: {
                                  color: "actif",
                                  value: item.id,
                                  "hide-details": "true",
                                  label:
                                    item.offerCount === 1
                                      ? item.name +
                                        " (" +
                                        item.offerCount +
                                        " offre)"
                                      : item.name +
                                        " (" +
                                        item.offerCount +
                                        " offres)"
                                },
                                model: {
                                  value: _vm.merchantsList,
                                  callback: function($$v) {
                                    _vm.merchantsList = $$v
                                  },
                                  expression: "merchantsList"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1779999396
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("v-divider", { staticClass: "filter-button-before" }),
      _c(
        "v-card-text",
        { staticClass: "filter-button" },
        [
          _c(
            "v-btn",
            {
              staticClass: "filter-button__btnA",
              attrs: {
                large: "",
                color: "shopfiltre_fond_bouton_A",
                block: "",
                rounded: "",
                "data-cy": "shop-filter-validate"
              },
              on: { click: _vm.validate }
            },
            [_vm._v(" APPLIQUER ")]
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "filter-button" },
        [
          _c(
            "v-btn",
            {
              staticClass: "filter-button__btnB",
              attrs: {
                large: "",
                color: "shopfiltre_fond_bouton_B",
                block: "",
                rounded: ""
              },
              on: { click: _vm.reset }
            },
            [_vm._v(" RÉINITIALISER ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }