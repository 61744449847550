var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "dashboard" },
        [
          _c(
            "div",
            {
              staticClass: "dashboard__container",
              attrs: { "data-cy": "dashboard-user" }
            },
            [
              !_vm.isMobile
                ? _c("PageTitle", {
                    staticClass: "dashboard__container-title",
                    attrs: { text: "Tableau de bord" }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "dashboard__container-line",
                  class: { "is-mobile": _vm.isMobile }
                },
                [
                  _vm.isMobile &&
                  _vm.isQuestionPublished &&
                  !_vm.currentUserService.revoked
                    ? _c("DashboardQuestion", {
                        staticClass: "dashboard__container-question",
                        nativeOn: {
                          click: function($event) {
                            return _vm.openQuestionDialog($event)
                          }
                        }
                      })
                    : _vm._e(),
                  _c("PointsHistory", {
                    key: _vm.forceBadPatternBalanceRefresh,
                    staticClass: "dashboard__container-points",
                    attrs: { limit: _vm.pointsLimit }
                  }),
                  !_vm.webViewIsActive
                    ? _c(
                        "DashboardProfileCard",
                        _vm._b(
                          { staticClass: "dashboard__container-profile" },
                          "DashboardProfileCard",
                          _vm.profileCardData,
                          false
                        )
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dashboard__container-line",
                  class: { "is-mobile": _vm.isMobile }
                },
                [
                  _vm.getFunctionality("SPONSORSHIP").length
                    ? _c("DashboardReferFriend", {
                        staticClass: "dashboard__container-line-referer",
                        attrs: {
                          referralCode: _vm.user.referralCode,
                          userServices: _vm.user.userServices
                        }
                      })
                    : _vm._e(),
                  !_vm.isMobile &&
                  _vm.getFunctionality("SPONSORSHIP").length &&
                  _vm.isQuestionPublished
                    ? _c("div", {
                        staticClass: "dashboard__container-line-separator"
                      })
                    : _vm._e(),
                  !_vm.isMobile &&
                  _vm.isQuestionPublished &&
                  !_vm.currentUserService.revoked
                    ? _c("DashboardQuestionWeb", {
                        staticClass: "dashboard__container-line-question",
                        on: { triggerQuestionDialog: _vm.openQuestionDialog }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dashboard__container-line",
                  class: { "is-mobile": _vm.isMobile }
                },
                [_c("DashboardLastOrder")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dashboard__container-line",
                  class: { "is-mobile": _vm.isMobile }
                },
                [_c("DashboardHintOffer")],
                1
              )
            ],
            1
          ),
          _c("QuestionDialog", {
            attrs: {
              question: _vm.question,
              points: _vm.rewardQuestionPoints,
              stepper: _vm.questionDialogStepper,
              error: _vm.error
            },
            on: {
              click: _vm.confirmQuestionDialog,
              close: _vm.closeQuestionDialog
            },
            model: {
              value: _vm.showQuestionDialog,
              callback: function($$v) {
                _vm.showQuestionDialog = $$v
              },
              expression: "showQuestionDialog"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }