var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "340px", "content-class": "error-dialog" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "error-dialog-card",
          attrs: { "data-cy": "error-dialog" }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "error-dialog-card-title",
              attrs: { "primary-title": "" }
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-card-text", { staticClass: "error-dialog-card-text" }, [
            _vm._v(" " + _vm._s(_vm.message) + " ")
          ]),
          _c("v-icon", { on: { click: _vm.closeDialog } }, [
            _vm._v("fas fa-times")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }