import Help from '@/views/account/Help.vue';
import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/account/Profile.vue';
import UpdateProfile from '@/views/account/UpdateProfile.vue';
import UpdatePassword from '@/views/account/UpdatePassword.vue';
import Cashback from '@/views/account/Cashback.vue';
import OrdersProfile from '@/views/account/OrdersProfile.vue';
import PointsHistoryContainer from '@/views/account/PointsHistoryContainer.vue';
import ScalePoints from '@/views/account/ScalePoints.vue';
import Documentation from '@/views/account/Documentation.vue';
import DeleteAccount from '@/views/account/DeleteAccount.vue';
import Applications from '@/views/account/Applications.vue';
import CalendarView from '../views/CalendarView.vue';

export default [
  {
    // Dashboard
    path: '/dashboard',
    name: 'dashboard',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { requiresAuth: true, title: 'Dashboard', breadCrumb: 'Tableau de bord' },
    children: [
      {
        // Dashboard
        path: '',
        name: 'dashboard:index',
        meta: { requiresAuth: true, title: 'Dashboard', breadCrumb: 'Tableau de bord' },
        component: Dashboard,
      },
      {
        // Profile
        path: 'profile',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: { requiresAuth: true, title: 'Profile', breadCrumb: 'Profil' },
        children: [
          {
            // Profile
            path: '',
            name: 'profile',
            meta: { requiresAuth: true, title: 'Profile', breadCrumb: 'Profil' },
            component: Profile,
          },
          {
            // update information
            path: 'update',
            name: 'update-profile',
            component: UpdateProfile,
            meta: { requiresAuth: true, title: 'Update profile', breadCrumb: 'Infos personnelles' },
          },
          {
            // udpate information
            path: 'updatePassword',
            name: 'update-password',
            component: UpdatePassword,
            meta: { requiresAuth: true, title: 'Update password', breadCrumb: 'Modifier mon mot de passe' },
          },
          {
            // Orders list
            path: 'orders',
            name: 'orders',
            component: OrdersProfile,
            meta: { requiresAuth: true, title: 'Orders', breadCrumb: 'Commandes' },
          },
          {
            // Scales points
            path: 'scalePoints',
            name: 'scalePoints',
            component: ScalePoints,
            meta: { requiresAuth: true, title: 'scalePoints', breadCrumb: 'Barème de points' },
          },
          {
            // History
            path: 'history',
            name: 'history',
            component: PointsHistoryContainer,
            meta: { requiresAuth: true, title: 'History', breadCrumb: 'Historique des points' },
          },
          {
            // Documentation
            path: 'documentation',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            meta: { requiresAuth: true, title: 'Documentation', breadCrumb: 'Confidentialité' },
            children: [
              {
                path: '',
                name: 'documentation',
                component: Documentation,
                meta: { requiresAuth: true, title: 'Documentation', breadCrumb: 'Confidentialité' },
              },
              {
                path: 'deleteAccount',
                name: 'DeleteAccount',
                component: DeleteAccount,
                meta: {
                  title: 'Delete account',
                  breadCrumb: 'Supprimer mon compte',
                },
              },
            ],
          },
          {
            // Refer a friend
            path: 'cashback',
            name: 'cashback',
            component: Cashback,
            meta: { requiresAuth: true, title: 'Cashback', breadCrumb: 'Cashback' },
          },
          {
            // Ask for help
            path: 'help',
            name: 'help',
            component: Help,
            meta: {
              title: 'Help',
              breadCrumb: "Besoin d'aide",
            },
          },
          {
            path: 'applications',
            component: {
              render(c) {
                return c('router-view');
              },
            },
            meta: {
              requiresAuth: true,
              title: 'Vos Applications',
              breadCrumb: 'Vos applications',
            },
            children: [
              {
                path: '',
                name: 'applications',
                component: Applications,
                meta: {
                  requiresAuth: true,
                  title: 'Vos Applications',
                  breadCrumb: 'Vos applications',
                },
              },
              {
                path: 'validate/:token',
                name: 'applicationSynchronisation',
                component: Applications,
                props: (route) => ({ ...route.params }),
                meta: {
                  title: 'Validation de la synchronisation',
                  breadCrumb: 'Validation de la synchronisation',
                },
              },
              {
                path: 'synchronisation/:status',
                name: 'anonymousApplicationSyncStatus',
                props: (route) => ({ ...route.params }),
                component: Applications,
                meta: {
                  title: 'Erreur',
                  breadCrumb: 'Erreur',
                  synchronisationStatus: 'anonymous',
                },
              },
              {
                path: ':appName',
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                meta: {
                  requiresAuth: true,
                  dynamicBreadCrumb: 'application',
                },
                children: [
                  {
                    path: '',
                    name: 'application',
                    props: (route) => ({ ...route.params }),
                    component: Applications,
                    meta: {
                      requiresAuth: true,
                      dynamicBreadCrumb: 'application',
                    },
                  },
                  {
                    path: ':status',
                    name: 'applicationSyncStatus',
                    props: (route) => ({ ...route.params }),
                    component: Applications,
                    meta: {
                      dynamicBreadCrumb: 'synchroStatus',
                      synchronisationStatus: 'named',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // Calendrier
    path: '/calendar',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { requiresAuth: true, title: 'Calendrier', breadCrumb: 'Calendrier' },
    children: [
      {
        path: '',
        name: 'calendar',
        component: CalendarView,
        meta: { requiresAuth: true, title: 'Calendrier', breadCrumb: 'Calendrier' },
      },
    ],
  },
];
