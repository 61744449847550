import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import store from '@/store';
import { SSOBehavior } from '@/services/merchantSpace/types';
import * as common from '@/services/common/common';
import { generatePKCE, getLastCodeVerifier } from './pkce-auth-flow.service';
import { decryptEmailBeforeAssign, isLoginResponse, signInCodeFlow } from '../../api/resources/ssoApi';
import { AuthTokens, SynchroResponse } from '../userSpace/types';
import { refreshUser, refreshUserPoints, autoApplySSOSynchro } from '../userSpace/UserService';
import { getRouter } from '@/libs/singleton';
import { PlatformKey } from '@/constants';
import Storage, { FORCE_SIGNIN_REDIRECT } from '@/libs/storage';

// TODO: make this less store dependant

const getPlatform = () => store.getters['platform/getPlatform'];
const isPkceAuthEnabled = () => store.getters['platform/isPkceAuthEnabled'];
const getSsoBehavior = () => store.getters['platform/ssoBehavior'];
const getExternalSigninURL = () => store.getters['platform/getExternalSignInURL'];
const getPrompt = () => store.getters['platform/ssoPrompt'];
const isConnected = () => common.isConnected();
const flashDanger = (...args) => store.dispatch('notifications/flashDanger', ...args);
const getClientId = () => process.env.VUE_APP_SSO_PARTNER_CLIENT_ID;
const getConnectionPath = () => {
  return process.env.VUE_APP_SSO_PARTNER_PATH ? process.env.VUE_APP_SSO_PARTNER_PATH : 'auth';
};

function getRedirectUri() {
  const platform = getPlatform();
  const { platformUrl, landingPath } = platform.config;

  return `${platformUrl}${landingPath}`;
}

export async function executeSSOFlow() {
  const ssoBehavior = getSsoBehavior();
  const { code, state, error } = getLoginResponse();

  if (error) {
    return;
  }

  if (ssoBehavior === SSOBehavior.AUTO_SIGNIN_TO_EXTERNAL && !code) {
    await startCodeFlow();
  } else if (code) {
    if (!state) {
      throw new Error('SSO: sign in without state params is forbidden');
    }

    // si un code est présent, on connecte l'utilisateur
    // ou on lui demande de s'inscrire (signer les cgus, etc...)
    await completeCodeFlow({ code, state });
  }
}

export const startCodeFlow = doAuthorizeCodeRequest;

async function doAuthorizeCodeRequest({ prompt = getPrompt() }: { prompt?: 'login' | 'none' } = {}) {
  const ssoBehavior = getSsoBehavior();
  const externalSigninURL = getExternalSigninURL();
  const connectionPath = getConnectionPath();

  if (![SSOBehavior.SIGNIN_TO_EXTERNAL, SSOBehavior.AUTO_SIGNIN_TO_EXTERNAL, SSOBehavior.WITH_CLASSIC].includes(ssoBehavior)) {
    return;
  }

  const params = await createParamsForCodeRequest();
  window.location.href = `${externalSigninURL}/${connectionPath}?${params}&prompt=${prompt}`;
}

async function completeCodeFlow({ code, state }: { code: string; state: string }) {
  if (isConnected()) {
    return;
  }
  const platform = getPlatform();
  const codeVerifier = isPkceAuthEnabled() ? getLastCodeVerifier() : undefined;
  const res = await signInCodeFlow({
    platformUUID: platform.uuid,
    state,
    code,
    ...(codeVerifier && { codeVerifier }),
    redirectUri: getRedirectUri(),
    clientId: getClientId(),
  }).catch((err) => {
    flashDanger({ message: 'Une erreur est survenue lors de la connexion. Merci de réessayer ultérieurement' });
    throw err;
  });

  const ssoBehavior = store.getters['platform/ssoBehavior'];
  const encryptedSynchroString = localStorage.getItem('synchroString');
  if (ssoBehavior === SSOBehavior.WITH_CLASSIC && encryptedSynchroString) {
    associateSSOAccount(encryptedSynchroString, platform.uuid, res);
    return;
  }

  if ((res as SynchroResponse).synchroURL) {
    store.dispatch('logoutMessageVisible', false);
    store.dispatch('showAskSSOSynchroMessage', true);
    store.dispatch('user/setUserSynchroURL', (res as SynchroResponse).synchroURL);
    return;
  }

  // user exists and api answer with an access token
  if (isLoginResponse(res)) {
    await loginAndRedirectToLanding(res.tokens);
  } else {
    // L'utilisateur n'existe pas. Il doit s'inscrire ou relier son "ancien" compte existant
    const router = getRouter();
    // Si d'autres plateformes viennent à utiliser cette feature, préférez gérer le cas à l'aide d'une option d'admin.
    // (et la table platform_config_sso)
    const routeName = platform.key === PlatformKey.BKLUB ? 'sso:pairing_mode_selection' : 'sso:pairing';
    router.push({
      name: routeName,
      query: {
        userInfos: res.userInfos,
        externalAccountId: res.metas.externalAccountId,
        externalAccessToken: res.metas.externalAccessToken,
        relatedTicketings: res.metas.relatedTicketings,
      },
    });
  }
}

// old name: loginAndRedirectUser
export async function loginAndRedirectToLanding(tokens: AuthTokens) {
  store.dispatch('user/login', { tokens });
  await Promise.all([refreshUser(), refreshUserPoints()]);
  redirectToLanding();
}

function redirectToLanding() {
  const router = getRouter();
  const platform = getPlatform();

  const forceRedirect = Storage.pull(FORCE_SIGNIN_REDIRECT);
  const pageToShow = platform.config.landingPath;
  if (forceRedirect || router.currentRoute.path !== pageToShow) {
    router.replace(forceRedirect || pageToShow);
  }
}

async function createParamsForCodeRequest(): Promise<URLSearchParams> {
  const state = generateStateParams();

  return new URLSearchParams({
    client_id: getClientId(),
    redirect_uri: getRedirectUri(),
    state,
    response_type: 'code',
    scope: ['profile', 'email', 'openid'].join(' '),
    // for public clients
    ...(isPkceAuthEnabled() && (await generatePKCE())),
  });
}

async function associateSSOAccount(encrypted: string, platformUUID: string, ssoResponse: any) {
  const router = getRouter();
  const decrypted = await decryptEmailBeforeAssign({ encrypted, platformUUID });
  const now = dayjs();
  if (decrypted.date && now.diff(dayjs(decrypted.date), 'minute', true) <= 5) {
    // same emails, we have a synchroURL
    if ((ssoResponse as SynchroResponse).synchroURL) {
      localStorage.removeItem('synchroString');
      window.location.href = (ssoResponse as SynchroResponse).synchroURL;
    } else {
      // le sso flow a reconnu que l'utilisateur sso existait déjà
      if (ssoResponse.tokens) {
        store.dispatch('autoSynchroError', true);
        store.dispatch('showConfirmSSOSynchroMessage', true);
        return;
      }

      // otherwise we force synchro and erase old email with the sso one
      try {
        const synchro = await autoApplySSOSynchro(ssoResponse.userInfos, platformUUID, encrypted);
        if (!synchro.tokens) {
          router.replace('/oops');
          return;
        }
        localStorage.removeItem('synchroString');
        store.dispatch('autoSynchroError', false);
        store.dispatch('showConfirmSSOSynchroMessage', true);
        await loginAndRedirectToLanding(synchro.tokens);
        return;
      } catch (error) {
        console.log('error', error);
        setTimeout(() => router.push('/'), 5000);
      }
    }
  } else {
    router.replace('/oops');
  }
}

function getLoginResponse() {
  const params = new URLSearchParams(window.location.search);
  return {
    code: params.get('code'),
    state: params.get('state'),
    error: params.get('error'),
  };
}

function generateStateParams() {
  return uuidv4();
}
