







































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import * as OfferService from '@/services/merchantSpace/OfferService';
import CarouselCard from '@/components/Card/OfferDetailCard/CarouselCard.vue';
import OfferInfo from '@/components/Card/OfferDetailCard/OfferInfo.vue';
import OfferDescription from '@/components/Card/OfferDetailCard/OfferDescription.vue';
import OfferDelivery from '@/components/Card/OfferDetailCard/OfferDelivery.vue';
import * as common from '@/services/common/common';
import Countdown from '@/components/Offer/Countdown.vue';
import OrdersNumber from '@/components/Offer/OrdersNumber.vue';
import { Offer } from '@/services/merchantSpace/types';

const moment = require('moment');

moment.locale('fr');

@Component({
  components: {
    CarouselCard,
    Countdown,
    OrdersNumber,
    OfferInfo,
    OfferDescription,
    OfferDelivery,
  },
})
export default class OfferDetailCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly offer!: Offer;

  get compTextForBtn(): string {
    let textBtn = '';
    switch (this.offer.offerTypeId) {
      case 1:
        textBtn = 'Acheter';
        break;
      case 2:
        textBtn = 'Participer';
        break;
      case 3:
        textBtn = 'Retour à la boutique';
        break;
      case 4:
        textBtn = 'Contribuer';
        break;
      case 5:
        textBtn = 'Acheter';
        break;
      default:
        textBtn = '';
    }
    return textBtn;
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get isStandardOffer(): boolean {
    return OfferService.isStandardOffer(this.offer.offerTypeId);
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.offer.offerTypeId);
  }

  get isShowcaseOffer(): boolean {
    return OfferService.isShowcaseOffer(this.offer.offerTypeId);
  }

  get isOffer(): boolean {
    return OfferService.isShowcaseOffer(this.offer.offerTypeId);
  }

  async onBuyClick() {
    if (this.isShowcaseOffer) {
      await this.$router.push(`/shop`);
    } else {
      this.$emit('click');
    }
  }

  get compPreviewsPhoto() {
    const photos = this.offer.previewPhotoUrl ? [`${process.env.VUE_APP_API_URL}/api${this.offer.previewPhotoUrl}`] : [];
    this.offer.photoUrls.forEach((pic) => {
      photos.push(`${process.env.VUE_APP_API_URL}/api${pic}`);
    });
    return photos;
  }

  showLogin(): void {
    this.$store.dispatch('showLogin', true);
  }

  get platformId() {
    return this.$store.getters['platform/getPlatform'].id;
  }
}
