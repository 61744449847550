












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Offer } from '@/services/merchantSpace/types';

@Component({
  components: {},
})

export default class CalendarCard extends Vue {
  @Prop({ type: String, required: true }) lockLabel!: string;

  @Prop({ type: Object, required: false }) offer!: Offer;

  @Prop({ type: Boolean, required: false }) loading!: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'black'
  })
  color!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  isSelectionable!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })

  isOpen!: boolean;


  openCase() {
    // Seul le clic fonctionne si la case est accessible
    if (this.isSelectionable) {
      this.$emit('open', this.offer)
    }
  }
}
