




























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import Avatar from '@/components/Avatar.vue';
import PageTitle from '@/components/PageTitle.vue';
import * as UserServices from '@/services/userSpace/UserService';
import ConfirmProfileUpdate from '@/components/Dialogs/ConfirmProfileUpdate.vue';
import { UserInfosForPatch, UpdateProfileFormRules } from '@/services/userSpace/types';
import { getUIConfig } from '@/configs';
import { PlatformKey } from '@/constants';

const moment = require('moment');

moment.locale('fr');

@Component({
  components: {
    ConfirmProfileUpdate,
    Avatar,
    PageTitle,
  },
})
export default class UpdateProfile extends Vue {
  formValid: boolean = false;

  showConfirm = false;

  couldChangeCardNumber: boolean = false;

  errorExternalAccountId = '';

  errorDateOfBirth = '';

  errorEmail = '';

  errorContactingService = '';

  mustReconnect: boolean = false;

  emailResent: boolean = false;

  emailResentLoading: boolean = false;

  emailResentError: string = '';

  snackbar: boolean = false;

  hasConfirmedEmail: boolean = false;

  userId: number = -1;

  form: UserInfosForPatch = {
    email: '',
    externalAccountId: '',
    lastname: '',
    firstname: '',
    phoneNumber: '',
    deliveryAdress: '',
    zipCode: null,
    city: '',
    newsletter: false,
  };

  initialForm: UserInfosForPatch = this.form;

  externalAccountIdLabel: string = '';

  formRules: UpdateProfileFormRules = UserServices.formRules;

  birthDateInvalid: boolean = false;

  validIsDisabled: boolean = true;

  emailAsExternal = false;

  async mounted() {
    this.emailResentError = '';
    await this.loadForm();

    this.$nextTick(() => {
      (this.$refs.updateProfileForm as Vue & { validate: () => boolean }).validate();
      this.birthDateInvalid =
        (
          this.$refs.dateOfBirth as Vue & {
            errorBucket: [];
          }
        ).errorBucket.length > 0;
    });

    this.externalAccountIdLabel = this.platform.style.signup.profilClientIdLabel;
  }

  async loadForm() {
    const { userInfosForPatch, additionalInfos } = await UserServices.getUserProfile();

    this.userId = additionalInfos.id;
    this.hasConfirmedEmail = additionalInfos.emailConfirmation;
    this.emailAsExternal = additionalInfos.emailAsExternal;

    this.initialForm = {
      ...userInfosForPatch,
      dateOfBirth: userInfosForPatch.dateOfBirth ? dayjs(userInfosForPatch.dateOfBirth).format('DD/MM/YYYY') : '',
    };
    this.form = { ...this.initialForm };

    const { activeUS, activeUSP } = additionalInfos;

    if (!activeUS) {
      this.couldChangeCardNumber = true;
    }

    if (activeUS && !activeUSP) {
      this.errorExternalAccountId = 'Carte invalide ou expirée';
      this.couldChangeCardNumber = true;
    }
  }

  get platform() {
    return this.$store.getters['platform/getPlatform'];
  }

  get externalAccountIdRule() {
    return UserServices.createExternalAccountIdRule(this.platform.config.externalAccountIdRegex)
  }

  get userNameAbbreviation() {
    const firstNameInitial = this.form.firstname ? this.form.firstname[0] : '';
    const lastNameInitial = this.form.lastname ? this.form.lastname[0] : '';
    return `${firstNameInitial}${lastNameInitial}`;
  }

  onExternalAccountIdChange() {
    if (this.initialForm.externalAccountId !== this.form.externalAccountId) {
      this.errorExternalAccountId = '';
    }
    this.onUserInfoChange();
  }

  get columnCount() {
    return this.$vuetify.breakpoint.smAndUp ? 10 : 5;
  }

  // TODO: create and use a plugin instead
  get showNewsletterSwitch() {
    return getUIConfig(this.platform.key, 'profil.showNewsletterSwitch');
  }

  get cardNumberIsEditable() {
    if (this.$store.getters['user/isReadOnly']) {
      return false;
    }

    return getUIConfig(this.platform.key, 'profil.cardNumberIsEditable');
  }

  get hasExternalAccountIdInput() {
    return ![PlatformKey.ASTUCE, PlatformKey.TBM].includes(this.platform.key)
  }

  async handleUpdateUserFields() {
    if ((this.$refs.updateProfileForm as Vue & { validate: () => boolean }).validate()) {
      try {
        const updatedUser = (await UserServices.updateUserFields(this.userId, this.form, 'fr')).data;

        if (updatedUser.email !== this.initialForm.email) {
          this.mustReconnect = true;
          this.showConfirm = true;
        } else {
          await Promise.all([
            UserServices.refreshUser(),
            UserServices.refreshUserPoints()
          ]);
        }
      } catch (e) {
        if (e.isAxiosError && e.response.status === 409) {
          if (e.response.data.message === 'NonUniqueUserException') {
            this.errorEmail = 'Ce compte existe déjà';
          }
          if (e.response.data.message === 'NonUniqueExternalAccountIdException') {
            this.errorExternalAccountId = 'Le numéro de compte existe déjà';
          }
          if (e.response.data.message === 'ExternalAccountIdValidationException') {
            this.errorExternalAccountId = 'Le couple numéro de compte/date de naissance ne correspond pas';
            this.errorDateOfBirth = 'Le couple numéro de compte/date de naissance ne correspond pas';
          }
          if (e.response.data.message === 'AccountValidationFailed') {
            this.errorExternalAccountId = 'Le couple numéro de compte/date de naissance ne correspond pas';
            this.errorDateOfBirth = 'Le couple numéro de compte/date de naissance ne correspond pas';
          }
          if (e.response.data.message === 'CardUpdateThresholdReached') {
            this.errorContactingService = `Le numéro de carte est bloqué pendant 24 heures suite à sa mise à jour. Veuillez réessayer plus tard.`;
          }
          if (e.response.data.message === 'ErrorContactingExternalAPI') {
            this.errorContactingService = 'Une erreur est survenu lors de le mise à jour du compte. Veuillez réessayer plus tard.';
          }
        }
        if (e.isAxiosError && e.response.status === 500) {
          this.errorContactingService = 'Une erreur est survenu lors de le mise à jour du compte. Veuillez réessayer plus tard.';
        }
        if (e.isAxiosError && e.response.status === 400) {
          if (Array.isArray(e.response.data.message)) {
            e.response.data.message.forEach((error: any) => {
              if (error.property === 'dateOfBirth') {
                this.errorDateOfBirth = 'Date de naissance invalide';
              }
            });
          }
        }
      }
    }
    this.validIsDisabled = true;
  }

  onUserInfoChange() {
    this.validIsDisabled = true;
    Object.keys(this.initialForm).forEach((key) => {
      if (this.initialForm[key] !== this.form[key]) {
        this.validIsDisabled = false;
      }
    });
  }

  get isDisabled(): boolean {
    return this.validIsDisabled;
  }

  get isDisabledEmail(): boolean {
    let disabled = false;
    if (this.initialForm.email !== this.form.email) {
      disabled = true;
    }

    return disabled;
  }

  closeConfirm() {
    this.showConfirm = false;
  }

  get hasReadOnlyRole() {
    return this.$store.getters['user/isReadOnly'];
  }

  async sendMail() {
    this.emailResentLoading = true;
    const result = await UserServices.sendConfirmationEmail('fr');
    // @ts-ignore : ts just get one type of response but axios can send multiple type of response
    if (result.isAxiosError && result.response.status === 409) {
      this.emailResentError = 'Votre adresse email est déjà validée';
      // For email field disabling
      this.hasConfirmedEmail = true;
    } else {
      this.emailResent = true;
    }
    this.emailResentLoading = false;
    this.snackbar = true;
  }
}
