import { apiAuthenticatedInstance } from '@/services/API';

export async function recordConsentHistory(consentData: {
  consentData: Record<string, any>;
  type: string;
  typeInfo: string;
  accepted: boolean;
  consentAt: string;
}) {
  try {
    await apiAuthenticatedInstance().post(`/api/consentHistory`, consentData);
  } catch (error) {
    console.error('Erreur lors de l’enregistrement du consentement:', error);
    throw error;
  }
}
