














import { Vue, Component, Prop } from 'vue-property-decorator';

const moment = require('moment');

@Component
export default class HistoryLine extends Vue {
  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly date!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly actionDebit!: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  readonly actionCredit!: string;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  readonly points!: number;

  @Prop({
    type: Number,
    required: true,
    default: 1,
  })
  readonly quantity!: number;

  get formatedDate(): string {
    return moment(this.date).format('DD/MM/YYYY');
  }

  get compAction() {
    let actionToDisplay: string = '';
    if (this.actionDebit) {
      actionToDisplay = this.actionDebit;
    } else {
      actionToDisplay = this.actionCredit;
    }

    return actionToDisplay;
  }

  get hideNoPointsReward(): boolean {
    return this.points === 0;
  }

  get compPoints() {
    const pointsString: string = `${this.points}`;
    let formatedPoints: string = '';
    if (this.actionDebit) {
      formatedPoints = pointsString.padStart(pointsString.length + 1, '-');
    } else {
      formatedPoints = pointsString;
    }

    return formatedPoints;
  }
}
