import { PaginatedResponse } from '@/services/common/Types.d';
import { Offer } from './types';
import * as offerApi from '@/api/resources/offerApi';

export const AVAILABLE: string = 'available';
export const ARCHIVED: string = 'archived';
export const PENDING_MODERATION: string = 'pending_moderation';
export const PENDING_PUBLICATION: string = 'pending_publication';

// Should the same id than in the database
export const offerTypeEnum = {
  STANDARD: {
    id: 1,
    type: 'standard',
  },
  LUCK: {
    id: 2,
    type: 'chance',
  },
  SHOWCASE: {
    id: 3,
    type: 'vitrine',
  },
  COLLECT: {
    id: 4,
    type: 'cagnotte',
  },
  CASHBACK: {
    id: 5,
    type: 'cashback',
  },
};

export const targetAudienceEnum = {
  PUBLIC: {
    id: 1,
    type: 'public',
  },
  PRO: {
    id: 2,
    type: 'pro',
  },
};

export const sortModesEnum = {
  DEFAULT: '-1',
  NEW_TO_OLD: '0',
  OLD_TO_NEW: '1',
  POINTS_INCREASING: '2',
  POINTS_DECREASING: '3',
};
// TODO: faire le ménage/déplacer
export interface Merchant {
  id: number;
  name: string;
  offerCount: number;
}

export interface GeoData {
  addr: string;
  positionX: string;
  positionY: string;
}

export interface FilterValues {
  geo: GeoData[];
  area: number;
  sortMode: string;
  filterPoints: number[];
  categories: number[];
  merchants: number[];
  offerTypes: number[];
  events: number[];
}

export interface Category {
  id: number;
  labelEn: string;
  labelFr: string;
}

export interface Event {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  offers: Offer[];
}

export class OfferNotFound extends Error {
  constructor(message = 'OfferNotFound') {
    super(message);
    this.name = 'OfferNotFound';
  }
}
export class OfferNotAvailable extends Error {
  constructor(message = 'OfferNotAvailable') {
    super(message);
    this.name = 'OfferNotAvailable';
  }
}

export const getOffers = async (page: number, limit: number, targetAudienceId: number): Promise<PaginatedResponse<Offer>> => {
  if (targetAudienceId === targetAudienceEnum.PRO.id) {
    return offerApi.getAllOffers({ page, limit });
  }

  return offerApi.getPublicOffers({ page, limit });
};

export const getOffersFiltered = async (
  page: number,
  limit: number,
  filterParams: FilterValues,
  targetAudienceId: number,
): Promise<PaginatedResponse<Offer>> => {
  const { sortMode, area } = filterParams;
  const minPoints = filterParams.filterPoints[0].toString();
  const maxPoints = filterParams.filterPoints[1].toString();

  let companiesIds = '';
  if (filterParams.merchants.length !== 0) companiesIds = filterParams.merchants.toString();

  let offerCategoriesIds = '';
  if (filterParams.categories.length !== 0) offerCategoriesIds = filterParams.categories.toString();

  let offerTypesIds = '';
  if (filterParams.offerTypes.length !== 0) offerTypesIds = filterParams.offerTypes.toString();

  let geoLoc: any[] = [];
  if (filterParams.geo.length !== 0) geoLoc = filterParams.geo;

  let eventsIds = '';
  if (filterParams.events.length !== 0) eventsIds = filterParams.events.toString();

  if (targetAudienceId === targetAudienceEnum.PRO.id) {
    return offerApi.getAllOffers({
      page,
      limit,
      geoLoc,
      area,
      sortMode,
      minPoints,
      maxPoints,
      companiesIds,
      offerCategoriesIds,
      offerTypesIds,
      eventsIds,
    });
  }
  return offerApi.getPublicOffers({
    page,
    limit,
    geoLoc,
    area,
    sortMode,
    minPoints,
    maxPoints,
    companiesIds,
    offerCategoriesIds,
    offerTypesIds,
    eventsIds,
  });
};

export const getOfferType = (id: number): string => {
  switch (id) {
    case offerTypeEnum.STANDARD.id:
      return offerTypeEnum.STANDARD.type;
    case offerTypeEnum.LUCK.id:
      return offerTypeEnum.LUCK.type;
    case offerTypeEnum.SHOWCASE.id:
      return offerTypeEnum.SHOWCASE.type;
    case offerTypeEnum.COLLECT.id:
      return offerTypeEnum.COLLECT.type;
    case offerTypeEnum.CASHBACK.id:
      return offerTypeEnum.CASHBACK.type;
    default:
      break;
  }
  return id === offerTypeEnum.LUCK.id ? offerTypeEnum.LUCK.type : offerTypeEnum.STANDARD.type;
};

export const getTargetAudience = (id: number): string => {
  return id === targetAudienceEnum.PUBLIC.id ? targetAudienceEnum.PUBLIC.type : targetAudienceEnum.PRO.type;
};

export const isStandardOffer = (id: number): boolean => {
  return getOfferType(id) === offerTypeEnum.STANDARD.type;
};

export const isCashbackOffer = (id: number): boolean => {
  return getOfferType(id) === offerTypeEnum.CASHBACK.type;
};

export const isLuckOffer = (id: number): boolean => {
  return getOfferType(id) === offerTypeEnum.LUCK.type;
};

export const isPublicOffer = (id: number): boolean => {
  return getTargetAudience(id) === targetAudienceEnum.PUBLIC.type;
};

export const isShowcaseOffer = (id: number): boolean => {
  return getOfferType(id) === offerTypeEnum.SHOWCASE.type;
};

export const isFundingOffer = (id: number): boolean => {
  return getOfferType(id) === offerTypeEnum.COLLECT.type;
};

export const getOfferById = async (id: number): Promise<Offer> => {
  try {
    return await offerApi.getOfferById(id);
  } catch (e) {
    if (e.isAxiosError && e.response) {
      if (e.response.status === 404) throw new OfferNotFound();
      if (e.response.status === 400) throw new OfferNotAvailable();
    }
    throw new Error();
  }
};
