








































































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as OfferService from '@/services/merchantSpace/OfferService';
import ErrorDialog from '@/components/Card/OfferDetailCard/ErrorDialog.vue';
import AlmostDoneCollectConfirmationCard from '@/components/Card/AlmostDoneCollectConfirmationCard.vue';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';
import { Offer, OrderAddress } from '@/services/merchantSpace/types';
import * as offerApi from '@/api/resources/offerApi';
import { getCookieConsentements } from '@/libs/cookies-consentment';

@Component({
  components: {
    ErrorDialog,
    AlmostDoneCollectConfirmationCard,
    OfferAvatar,
  },
})
export default class RequestConfirmationCard extends Vue {
  loading: boolean = false;

  showDialog: boolean = false;

  dialogText: string = '';

  standardOfferMessage: string = `Vous êtes sur le point d'effectuer un achat. Après confirmation, vous ne pourrez plus revenir en arrière. `;

  luckOfferMessage: string = `Vous êtes sur le point de participer à une offre chance. Après confirmation, vous ne pourrez plus revenir en arrière. `;

  fundingOfferMessage: string = `Vous êtes sur le point de contribuer à cette cagnotte. Suite à celle-ci vous ne pourrez plus revenir en arrière. `;

  fundingOfferMessage2: string = `Votre contribution est de 10 points minimum. Vous avez la possibilité de donner le nombre de points que vous souhaitez grâce au curseur de points dans la limite de vos points disponibles.`;

  pointsToGive: number = 10;

  remainingPoints: number = 0;

  showAlmostDoneMessage = false;

  @Prop({ type: Object, required: true })
  readonly offer!: Offer;

  @Prop({ type: String, required: true })
  readonly companyName!: string;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Number, required: true })
  readonly points!: number;

  @Prop({ type: Number, required: true })
  readonly currentUserPoints!: number;

  @Prop({ type: Object, required: false })
  readonly orderAddress!: OrderAddress;

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  readonly companyAvatar!: string;

  get subtitleMessage(): string {
    if (this.isFundingOffer) {
      return this.fundingOfferMessage;
    }
    return this.isStandardOffer ? this.standardOfferMessage : this.luckOfferMessage;
  }

  get subtitleMessage2(): string {
    return this.fundingOfferMessage2;
  }

  get maxPointsToGive(): number {
    if (this.offer.maxPoints) {
      return this.currentUserPoints < this.offer.maxPoints ? this.currentUserPoints : this.offer.maxPoints;
    }
    return this.currentUserPoints;
  }

  get titleText(): string {
    if (this.isFundingOffer) {
      return 'Contribution';
    }
    return this.isStandardOffer ? `Confirmation d'achat` : 'Confirmation de participation';
  }

  get buttonText(): string {
    if (this.isFundingOffer) {
      return 'Confirmer ma contribution';
    }
    return this.isStandardOffer ? 'Confirmer mon achat' : 'Confirmer ma participation';
  }

  get isStandardOffer(): boolean {
    return OfferService.isStandardOffer(this.offer.offerTypeId);
  }

  get isFundingOffer(): boolean {
    return OfferService.isFundingOffer(this.offer.offerTypeId);
  }

  async onConfirmClick(scaledPoints?: boolean) {
    this.loading = true;
    try {
      if (this.isFundingOffer) {
        let points = 0;
        points = this.pointsToGive;
        if (scaledPoints === true) {
          points = this.remainingPoints;
        }
        const contributionCheck = await offerApi.contributionCheck(this.offer.id, points);
        if (!contributionCheck.exceeded) {
          const contributeFunding = await offerApi.contributeFundingOffer(this.offer.id, points);
          if (contributeFunding) {
            this.$emit('click', true);
          }
        } else {
          this.remainingPoints = contributionCheck.remainingPoints;
          this.showAlmostDoneMessage = true;
          this.loading = false;
          return;
        }
      } else {
        let order: any;
        try {
          order = await offerApi.consumeOffer(this.offer.id, this.orderAddress);
          this.$emit('order', order);

          if (order[0].uuid.error === 'NOT_AVAILABLE') {
            this.$emit('click', false);
          }
          if (order[0].uuid && !order[0].uuid.error) {
            this.$emit('click', true);
          }
        } catch (error) {
          throw error;
        }
      }
      const cookieConsent = getCookieConsentements('tarteaucitron');

      if (cookieConsent && cookieConsent.facebookpixel === true && window.fbq) {
        window.fbq('track', 'Purchase', {
          value: this.offer.points,
          currency: 'USD',
          content_name: this.offer.title,
          content_ids: [this.offer.id],
        });
      }
    } catch (err) {
      if (err.response.status === 403) {
        this.dialogText = 'Cette offre n’est plus disponible';
      } else {
        this.dialogText = this.isStandardOffer
          ? 'Une erreur est survenue lors de l’achat de cette offre. Vous pouvez retenter ultérieurement. Si le problème persiste, contactez le support. '
          : `Une erreur est survenue lors de la participation à cette offre. Vous pouvez retenter ultérieurement. Si le problème persiste, contactez le support. `;
      }
      this.showDialog = true;
    }
    this.loading = false;
  }

  get compCompanyAvatar() {
    return this.companyAvatar ? `${process.env.VUE_APP_API_URL}/api${this.companyAvatar}` : '';
  }

  get companyInitials() {
    return this.companyName.charAt(0).toUpperCase();
  }

  get formatAddress() {
    const address = this.orderAddress;
    if (address) {
      return `${address.address}, ${address.zipCode} ${address.city}`;
    }
    return '';
  }
}
