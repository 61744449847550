import { Route } from 'vue-router';
import { PageName } from '@/constants';
import { getOfferById } from '@/services/merchantSpace/OfferService';
import store from '@/store';

export function synchronizationPageGuard(to, from, next) {
  if (to.meta.synchronisationStatus) {
    if (to.meta.synchronisationStatus === 'anonymous') {
      if (['expiredToken', 'alreadySynchronized', 'badToken', 'unknownError'].includes(to.params.status)) {
        next();
      } else {
        next({ name: 'applications' });
      }
    } else if (to.meta.synchronisationStatus === 'named') {
      if (['mailSent', 'synchroSuccess'].includes(to.params.status)) {
        next();
      } else {
        next({ name: 'applications' });
      }
    }
  } else {
    next();
  }
}

export function authGuards(to, from, next) {
  if (to.meta.requiresAuth && !store.getters['user/getAccessToken']) {
    next({ name: 'home' });
  } else {
    next();
  }
}

// Define a function to validate the query parameters for the stateless auth.
// zod or lodash or underscore would have been a better choice. None of them are included in the project.
// I tried zod but our typescript version is too old. cry emoji.
const validateQueryParams = (query: any) => {
  if (typeof query.accessToken !== 'string' || query.accessToken.trim() === '') {
    throw new Error('Invalid accessToken');
  }

  if (typeof query.refreshToken !== 'string' || query.refreshToken.trim() === '') {
    throw new Error('Invalid refreshToken');
  }

  if (typeof query.redirectUrl !== 'string' || query.redirectUrl.trim() === '') {
    throw new Error('Invalid redirectUrl');
  }

  return {
    accessToken: query.accessToken,
    refreshToken: query.refreshToken,
    redirectUrl: query.redirectUrl,
  };
};

/**
 * This route guard is used to intercept stateless authorization.
 * A flow that does not require authentication but if the user is already authenticated,
 * we replace the authentication session.
 *
 * The query parameters are expected to be in this format:
 * { access: string, refresh: string, redirectUrl: string }
 *
 * The access and refresh tokens is from the authentication server.
 * The redirectUrl is the route location to authorize the user to access first.
 *
 * @param to the target route location being navigated to.
 * @param _ the current route location being navigated from.
 * @param next a callback to be called to resolve the navigation guard.
 */
export const statelessAuthorizationGuard = async (to: Route, _: Route, next: (location?: any) => void) => {
  // We know that the route is stateless authorization but let's check anyway.
  if (to.name === 'stateless-authorization') {
    try {
      // Validate the query parameters using the schema.
      const { accessToken, refreshToken, redirectUrl } = validateQueryParams(to.query);

      // The actual login logic is encapsulated.
      await store.dispatch('user/login', { tokens: { accessToken, refreshToken } });

      // Redirect to the intended route location.
      next(redirectUrl);
    } catch (error) {
      // If there is an error, move to the next guard.
      next("/");
    }
  } else {
    next("/");
  }
};

export function hideFromAuth(to, from, next) {
  if (store.getters['user/getAccessToken'] && to.meta.hideFromAuth) {
    const pageToShow = store.getters['platform/getPlatform'].config.landingPath;
    next({
      path: pageToShow,
      query: { ...to.query },
    });
  } else {
    next();
  }
}

export async function prepareBreadcrumb(to, from, next) {
  if (!to.meta.breadCrumb || !to.meta.dynamicBreadCrumb) {
    next();
    return;
  }

  const pathArray = to.path.split('/');

  pathArray.shift();

  if (pathArray.length === 1 && pathArray[0] !== 'dashboard') {
    pathArray.unshift('home');
  }

  const breadCrumbs = await (pathArray as any).reduce(async (breadcrumbArray, path, idx) => {
    let curArray;
    if (breadcrumbArray instanceof Promise) {
      curArray = await breadcrumbArray;
    } else {
      curArray = breadcrumbArray;
    }
    if (to.matched[idx] && to.matched[idx].meta) {
      if (to.matched[idx].meta.breadCrumb && !to.matched[idx].meta.dynamicBreadCrumb) {
        curArray.push({
          path,
          exact: true,
          disabled: idx === pathArray.length - 1,
          to: buildPath(curArray, idx, path),
          text: to.matched[idx] ? to.matched[idx].meta.breadCrumb : path,
          dynamic: false,
        });
      }
      if (to.matched[idx].meta.dynamicBreadCrumb) {
        if (to.matched[idx].meta.dynamicBreadCrumb === 'offer') {
          const offerId = Number.parseInt(to.params.offerId, 10);
          let { title } = await getOfferById(offerId);
          if (to.matched[idx].meta.dynamicView) {
            title += ` - ${to.matched[idx].meta.dynamicView}`;
          }
          if (title) {
            curArray.push({
              path,
              exact: true,
              disabled: idx === pathArray.length - 1,
              to: buildDynamicPath(curArray, idx, path, offerId),
              text: title,
              dynamic: true,
            });
          }
        } else if (to.matched[idx].meta.dynamicBreadCrumb === 'application') {
          const appName = to.params.appName as string;
          curArray.push({
            path,
            exact: true,
            disabled: idx === pathArray.length - 1,
            to: buildPath(curArray, idx, path),
            text: appName,
            dynamic: false,
          });
        } else if (to.matched[idx].meta.dynamicBreadCrumb === 'synchroStatus') {
          const synchroStatus = to.params.status as string;
          curArray.push({
            path,
            exact: true,
            disabled: idx === pathArray.length - 1,
            to: buildPath(curArray, idx, path),
            text: synchroStatus === 'mailSent' ? 'Email envoyé' : 'Application synchronisée',
            dynamic: false,
          });
        }
      }
    }
    return curArray.filter((value) => value.path);
  }, []);
  await store.dispatch('user/setBreadCrumb', breadCrumbs);
  next();
}

export async function prepareNavTabs(to, breadcrumbs: any) {
  let tabTitle = '';
  const platform = store.getters['platform/getPlatform'];
  const goTo = to.matched[to.matched.length - 1];
  if (goTo) {
    if (goTo.meta.breadCrumb && !goTo.meta.dynamicBreadCrumb) {
      tabTitle = goTo.meta.breadCrumb;
    } else if (goTo.meta.dynamicBreadCrumb) {
      tabTitle = breadcrumbs[breadcrumbs.length - 1].text;
    } else {
      tabTitle = goTo.meta.title;
    }
    tabTitle += ` - ${platform.name}`;
    return tabTitle;
  }
  return '';
}

export async function synchronizationSSOGuard(to, from, next) {
  if (to.name === 'sso:account_sync') {
    const { signature, expires, platformUUID, userInfos } = to.query;
    if (!signature || !expires || !platformUUID || !userInfos) {
      next({ path: '/oops', name: PageName.ERROR });
    } else {
      next();
    }
  } else {
    next();
  }
}

function buildPath(breadcrumbArray, idx, path) {
  const computedPath = breadcrumbArray[idx - 1] ? `${breadcrumbArray[idx - 1].to}/${path}` : `/${path}`;
  return computedPath.replace('//', '/');
}

function buildDynamicPath(breadcrumbArray, idx, path, data) {
  let computedPath;
  let index = idx;
  if (breadcrumbArray.length < index - 1) {
    index = breadcrumbArray.length + 1;
  }

  if (breadcrumbArray[index - 2]) {
    computedPath = `${breadcrumbArray[index - 2].to}/${data}/${path}`;
  } else {
    computedPath = `/${data}/${path}`;
  }
  return computedPath.replace('//', '/');
}
