var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar__case CalendarCard",
      class: { "disabled-case": !_vm.isSelectionable },
      style: { "--calendar-card-color": _vm.color },
      on: { click: _vm.openCase }
    },
    [
      _c("v-card-text", { staticClass: "calendar__case-container" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("v-progress-circular", {
                  staticClass: "mx-auto",
                  attrs: { indeterminate: "", color: "primary" }
                })
              ],
              1
            )
          : !_vm.isOpen
          ? _c("div", { staticClass: "text-center" }, [
              _c("h2", { staticClass: "calendar__case-number" }, [
                _vm._v(" " + _vm._s(_vm.lockLabel) + " ")
              ])
            ])
          : _c("div", { staticClass: "case-back text-center" }, [
              _c("h2", { staticClass: "calendar__case-title text-h5" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.offer ? _vm.offer.company.name : "Vide") +
                    " "
                )
              ]),
              _vm.offer
                ? _c("div", { staticClass: "calendar__case-description" }, [
                    _vm._v(" " + _vm._s(_vm.offer.title) + " ")
                  ])
                : _vm._e()
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }