var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideNoPointsReward
    ? _c(
        "div",
        { staticClass: "history-line" },
        [
          _c(
            "v-row",
            { staticClass: "history-line__content" },
            [
              _c("v-col", { attrs: { cols: "3" } }, [
                _vm._v(_vm._s(_vm.formatedDate))
              ]),
              _c("v-col", { attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.compAction))
              ]),
              _c("v-col", { attrs: { cols: "3" } }, [
                _vm._v(_vm._s(_vm.compPoints) + " Points")
              ]),
              _c("v-col", { attrs: { cols: "2" } }, [
                _vm._v(_vm._s(_vm.quantity))
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }