import { ServiceKey } from '@/constants';

export enum SSOBehavior {
  NONE = 'none', // sso non configuré
  SIGNIN_TO_EXTERNAL = 'signin_to_external', // l'utilisateur sera redirigé vers le serveur d'auth du partenaire à la suite d'une action (click sur bouton "connecter" par exemple)
  AUTO_SIGNIN_TO_EXTERNAL = 'auto_signin_to_external', // l'utilisateur est redirigé vers le serveur d'auth du partenaire dès son arrivé sur notre appli
  PASSWORD = 'password', // le sso requiert un mot de passe utilisateur
  WITH_CLASSIC = 'with_classic', // propose la connexion classique en plus du sso
}

export interface Service {
  id: number;
  name: string;
  key: ServiceKey;
  typeId: number;
  description: string;
  emailAsExternal: boolean;
  productCodeAsExternalAccountId: boolean;
}

export interface UserServiceProduct {
  id: number;
  productCode: string;
  revoked: boolean;
  revokedAt: string;
  createdAt: string;
  cumulative: number;
  synchronized: boolean;
}

export interface UserService {
  id: number;
  createdAt: Date | string;
  revoked: boolean;
  revokedAt?: Date | string;
  validated: boolean;
  validatedAt?: Date | string;
  userId: number;
  externalAccountEmail: string;
  externalAccountId: string;
  userServiceProducts: UserServiceProduct[];
  identifierTypeSlug?: string;
  serviceId: number;
  service: Service;
}

export interface Offer {
  id: number;
  offerTypeId: number;
  title: string;
  description: string;
  discountDescription: string;
  points: number;
  discountTypeId: number;
  offerCategoryId: number;
  deliveryMethodId: number;
  companyId: number;
  companyName: string;
  companyAvatar?: string;
  websiteURL: string;
  startedAt?: Date;
  expiredAt?: Date;
  photoUrls: string[];
  previewPhotoUrl?: string;
  collectionMailAdressOption: number;
  collectionMailAdress: string;
  collectionDateOption: number;
  collectionDateUnique?: Date;
  collectionDateMin?: Date;
  collectionDateMax?: Date;
  creatorId: number;
  company?: {
    name: string;
  };
  modifierId?: number;
  status: 'available' | 'archived' | 'pending_moderation' | 'pending_publication';
  archivedAt?: Date;
  availableAt?: Date;
  drawAt?: Date;
  targetAudienceId: number;
  initialStock?: number;
  currentStock?: number;
  nbOrders?: number;
  site?: string;
  maxPoints?: number;
  filledPoints?: number;
  furnisherName?: string;
  externalId?: string;
  previewPhoto?: {
    filename: string;
    uuid: string;
  };
}

export interface Order {
  id: number;
  uuid: string;
  platformId: number;
  platform?: number;
  userId: number;
  offerId: number;
  offer?: Offer;
  createdAt: Date;
  stockBefore: number;
  uniquePromoCodeAvailable: boolean;
  uniquePromoCode: string;
  uniquePromoCodeExpireDate?: Date;
  action?: any;
  email: string;
  lastname: string;
  firstname: string;
  trackingUrl?: string;
}

export interface OrderAddress {
  address: string;
  zipCode: number;
  city: string;
}
