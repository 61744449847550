var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CalendarBanner fade-in", style: _vm.rootCSS },
    [
      _c("div", { staticClass: "description-preview mb-3" }, [
        _vm._v(
          " " +
            _vm._s(_vm.calendarBanner.description || "Description en attente") +
            " "
        )
      ]),
      _c(
        "v-btn",
        {
          staticClass: "discover-btn",
          style: {
            color: _vm.style.button.textColor + " !important",
            "background-color": _vm.style.button.backgroundColor + " !important"
          },
          attrs: { to: { name: "calendar" }, rounded: "", elevation: "0" }
        },
        [_vm._v(" Découvrir ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }