var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CalendarOpenDay" }, [
    _c(
      "div",
      {
        staticClass: "text-center slide-in",
        style: { "--stagger-delay": "0.35s" }
      },
      [
        _c("h1", { staticClass: "open-title text-h2 mb-4" }, [
          _vm._v(" Félicitations ! ")
        ]),
        _c("h2", { staticClass: "open-subtitle text-subtitle-1 mb-6" }, [
          _vm._v(" Vous venez de débloquer une nouvelle offre: ")
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "offer-container mb-12 slide-in",
        style: { "--stagger-delay": "0.65s" },
        on: {
          click: function($event) {
            return _vm.onOfferCardClick(_vm.offer.id)
          }
        }
      },
      [
        _c("OfferCard", {
          attrs: {
            imageUrl: _vm.previewPhotoUrl,
            drawAt: _vm.offer.drawAt,
            offerId: _vm.offer.id,
            offerTitle: _vm.offer.title,
            points: _vm.offer.points,
            companyName:
              _vm.offer.company && _vm.offer.company.name
                ? _vm.offer.company.name
                : _vm.offer.companyName,
            offerTypeId: _vm.offer.offerTypeId,
            targetAudienceId: _vm.offer.targetAudienceId,
            companyLogoUrl: _vm.offer.companyAvatar,
            filledPoints: _vm.offer.filledPoints || 0,
            maxPoints: _vm.offer.maxPoints
          }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "actions-menu slide-in",
        style: { "--stagger-delay": "0.8s" }
      },
      [
        _c(
          "v-btn",
          {
            staticClass: "back-btn",
            attrs: {
              color: "homebloc_fond_bouton",
              large: "",
              rounded: "",
              elevation: "0"
            },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v(" Calendrier de l'avent ")]
        ),
        _c(
          "v-btn",
          {
            staticClass: "lookat-btn",
            attrs: {
              large: "",
              rounded: "",
              color: "homeglobal_fond_bouton",
              elevation: "0",
              to: {
                name: "offerDetail",
                params: {
                  offerId: _vm.offer && _vm.offer.id
                }
              }
            }
          },
          [_vm._v(" Voir ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }