










import { Vue, Component, Prop } from 'vue-property-decorator';
import * as OrderService from "@/services/merchantSpace/OrderService";

@Component({
  components: {},
})
export default class OrdersNumber extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  readonly offerId!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly platformId!: number;

  ordersNumber: string = '';

  async created() {
    this.ordersNumber = await OrderService.getOrdersCount(this.offerId, this.platformId);
  }
}
