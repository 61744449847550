import { recordConsentHistory } from '@/api/resources/consentApi';
import { isConnected } from '@/services/common/common';

interface Consentements {
  facebookpixel?: boolean;
  gtag?: boolean;
}

export function loadCookiesConsentment({ scriptId = 'cookies-consentment' } = {}) {
  if (document.getElementById(scriptId)) {
    return;
  }

  const libLink = process.env.VUE_APP_TARTEAUCITRON_URI || 'https://tarteaucitron.io/load.js?domain=ireby.fr&uuid=bb0abdec9792ea8708613510b8bc69faaef98895';

  const script = document.createElement('script');
  script.setAttribute('id', scriptId);
  script.setAttribute('src', libLink);
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('defer', '');

  const previousConsentState: Record<string, boolean | undefined> = {};

  script.addEventListener('load', () => {
    (window as any).tarteaucitron.initEvents.loadEvent();

    const checkAndHandleConsentChange = (service: string) => {
      if (service === 'facebookpixel' && isConnected()) {
        setTimeout(() => {
          const currentConsent = (window as any).tarteaucitron.state[service] === true;

          if (previousConsentState[service] !== currentConsent) {
            previousConsentState[service] = currentConsent;
            handleConsentChange(service, currentConsent);
          }
        }, 100);
      }
    };

    (window as any).tarteaucitron.job.forEach(checkAndHandleConsentChange);

    let observerTimeout;
    const observer = new MutationObserver(() => {
      if (observerTimeout) {
        clearTimeout(observerTimeout);
      }
      observerTimeout = setTimeout(() => {
        (window as any).tarteaucitron.job.forEach(checkAndHandleConsentChange);
      }, 200);
    });

    observer.observe(document.body, { childList: true, subtree: true });
  });

  document.head.appendChild(script);
}

async function handleConsentChange(state, status) {
  if (state !== 'facebookpixel') {
    return;
  }
  const consentData = {
    platform: process.env.VUE_APP_PLATFORM_UUID,
  };
  const consentHistory = {
    consentData,
    type: 'pixel',
    typeInfo: 'PixelConsent',
    accepted: status,
    consentAt: new Date().toISOString(),
  };
  await recordConsentHistory(consentHistory);
}

export function getCookieConsentements(cookieName): Consentements | null {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${cookieName}`));

  if (!cookie) {
    return null;
  }

  const consentements = cookie.split('!');
  const consentementsObj: Consentements = {};

  consentements.forEach((consentement) => {
    const [key, value] = consentement.split('=');
    if (key && value) {
      consentementsObj[key] = value === 'true';
    }
  });

  return consentementsObj;
}
