



















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { SSOBehavior } from '@/services/merchantSpace/types';
import { startCodeFlow } from '@/services/authSpace/sso.service';
import Storage, { FORCE_SIGNIN_REDIRECT } from '@/libs/storage';

@Component
export default class SignInButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  text!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  plain!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  block!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  ripple!: boolean;

  @Prop({
    required: false,
    default: undefined,
  })
  color: any;

  @Prop({
    required: false,
    default: undefined,
  })
  elevation: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  large: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  rounded: any;

  @Prop({
    type: Object,
    required: false,
  })
  forceRedirectTo: any;

  @Watch('isSignInDialogShown')
  onSignInDialogChanged (isVisible) {
    if (!isVisible) {
      Storage.remove(FORCE_SIGNIN_REDIRECT)
    }
  }

  get ssoBehavior(): SSOBehavior {
    return this.$store.getters['platform/ssoBehavior'];
  }

  get isSignInDialogShown() {
    return this.$store.getters.loginVisible;
  }

  // Do not remove.
  // This method can be use by a parent component
  click() {
    this.showSignIn();
  }

  showSignIn() {
    if (this.forceRedirectTo) {
      Storage.save(FORCE_SIGNIN_REDIRECT, this.forceRedirectTo);
    }

    if ([SSOBehavior.AUTO_SIGNIN_TO_EXTERNAL, SSOBehavior.SIGNIN_TO_EXTERNAL].includes(this.ssoBehavior)) {
      startCodeFlow();
    } else if (this.ssoBehavior === SSOBehavior.PASSWORD) {
      this.$router.push({ name: 'sso:signin' });
    } else {
      this.$store.dispatch('showLogin', true);
    }

    this.$emit('click');
  }
}
