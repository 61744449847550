














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import OfferCard from '@/components/Card/OfferCard.vue';
import { Offer } from '@/services/merchantSpace/types';
import joinPath from '@/utils/joinPath';
import SignInButton from '../Button/SignInButton.vue';

@Component({
  components: {
    SvgPopup,
    OfferCard,
    SignInButton
  },
})
export default class CalendarOpenDay extends Vue {
  @Prop({ type: Object, required: true }) offer!: Offer;

  onOfferCardClick(offerId: number): void {
    this.$router.push({
      name: 'offerDetail',
      params: {
        offerId: offerId.toString()
      }
    });
  }

  get previewPhotoUrl() {
    if(!this.offer.previewPhoto) {
      return '';
    }

    return `/files/offer/${this.offer.previewPhoto.uuid}`;
  }

  get drawAt() {
    return this.offer.drawAt ? dayjs(this.offer.drawAt, 'YYYY-MM-DD').toDate() : null
  }

  get isConnected(): boolean {
    return this.$store.getters['user/isConnected'];
  }
}
