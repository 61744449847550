export function loadFontFace(fontFamily: string, fontUrl: string) {
  // @ts-ignore
  const fontLoader = new FontFace(fontFamily, `url(${fontUrl})`);
  return fontLoader
    .load()
    .then((loadedFont) => {
      (document as any).fonts.add(loadedFont);
    })
    .catch((error) => {
      console.error('cannot load font', fontFamily, error);
    });
}
