import { get } from 'lodash-es';
import { PlatformKey } from '@/constants';

export enum AgeRestriction {
  MINOR_IS_RESTRICT = 'minor_is_restrict',
  MINOR_NEED_CONSENT = 'minor_need_concent',
}

// TODO: create a vue plugin from these configs
const ui = {
  [PlatformKey.STAN]: {
    signup: {
      ageRestriction: AgeRestriction.MINOR_IS_RESTRICT,
    },
  },

  [PlatformKey.IRIGO]: {
    profil: {
      showNewsletterSwitch: false,
    },
  },

  [PlatformKey.ASTUCE]: {
    signup: {
      enableMultipleRegister: false,
    },
  },

  [PlatformKey.BKLUB]: {
    profil: {
      showNewsletterSwitch: false,
    },
  },

  [PlatformKey.STAR]: {
    profil: {
      showNewsletterSwitch: false,
      cardNumberIsEditable: false,
    },
  },

  default: {
    signup: {
      ageRestriction: AgeRestriction.MINOR_NEED_CONSENT,
      enableMultipleRegister: true,
    },

    profil: {
      showNewsletterSwitch: true,
      cardNumberIsEditable: true,
    },
  },
};

export function getUIConfig(platformKey: PlatformKey, configKey: string) {
  const result = resolveConfig(platformKey, configKey);
  return result !== undefined ? result : resolveConfig('default', configKey);
}

function resolveConfig(platformKey: PlatformKey | 'default', configKey: string) {
  return get(ui, `${platformKey}.${configKey}`);
}
