import Help from '@/views/account/Help.vue';
import Home from '@/views/Home.vue';
import Signup from '@/views/Signup.vue';
import ScalePoints from '@/views/account/ScalePoints.vue';
import Shop from '@/views/Shop.vue';
import OfferDetails from '@/views/offer/OfferDetails.vue';
import EmailConfirmation from '@/views/EmailConfirmation.vue';
import ResponsiveTest from '@/views/ResponsiveTest.vue';
import ResetPassword from '@/views/account/ResetPassword.vue';
import ExpiredToken from '@/views/account/ExpiredToken.vue';
import LegalNotice from '@/views/legal-sections/LegalNotice.vue';
import UserRoute from '@/views/legal-sections/UserRoute.vue';
import RGPDContent from '@/views/legal-sections/RGPDContent.vue';
import CookiesPolicy from '@/views/legal-sections/CookiesPolicy.vue';
import Maintenance from '@/views/Maintenance.vue';
import CashbackError from '@/views/CashbackError.vue';
import SSOSignInView from '@/views/SSOSignInView.vue';
import SSOSignInForm from '@/components/SSO/SSOSignInForm.vue';
import SSOPairingForm from '@/components/SSO/SSOPairingForm.vue';
import SSOPairingModeSelection from '@/components/SSO/SSOPairingModeSelection.vue';
import SSOBindExistingAccountForm from '@/components/SSO/SSOBindExistingAccountForm.vue';
import SSOAccountBindingView from '@/views/SSOAccountBindingView.vue';
import store from '@/store';
import Error from '@/views/Error.vue';
import { PageName } from '@/constants';
import CalendarView from '../views/CalendarView.vue';
import SSOAccountSyncronizeView from '@/views/SSOAccountSyncronizeView.vue';
import RGPDEcomotiveApp from '@/views/legal-sections/RGPDEcomotiveApp.vue';
import { statelessAuthorizationGuard } from './utils';

// TODO: fix that. webViewIsActive doit toujours correspondre à la config en base de données
// Or, à ce moment là, le store n'a pas connaissance de la bdd
// (soit l'info provient du localStorage, soit du state par défaut)
const webViewIsActive = store.getters['platform/webViewIsActive'];

export default [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      requiresAuth: false,
      isHome: false,
      title: 'Maintenance en cours',
      breadCrumb: 'Maintenance',
      public: true,
    },
    component: Maintenance,
  },
  {
    // Homepage
    path: '/',
    name: PageName.HOME,
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: false,
      isHome: true,
      title: 'Home',
      breadCrumb: 'Accueil',
      public: true,
    },
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
        meta: {
          requiresAuth: false,
          isHome: true,
          title: 'Home',
          breadCrumb: 'Accueil',
          public: true,
          hideFromAuth: true,
        },
      },
      {
        path: 'sso/signin',
        component: SSOSignInView,
        meta: {
          requiresAuth: false,
          isSignup: true,
        },
        children: [
          {
            path: '',
            component: SSOSignInForm,
            name: 'sso:signin',
            meta: {
              title: 'Connexion',
            },
          },
          {
            path: 'pairing',
            component: SSOPairingForm,
            name: 'sso:pairing',
            props(route) {
              const { relatedTicketings } = route.query;

              return {
                userInfos: route.query.userInfos,
                externalAccountIdFromSSO: route.query.externalAccountId,
                externalAccessToken: route.query.externalAccessToken,
                relatedTicketings: typeof relatedTicketings === 'string' ? [relatedTicketings] : relatedTicketings,
              };
            },
            meta: {
              title: 'Inscription',
            },
          },
        ],
      },
      {
        path: '/sync',
        component: SSOAccountSyncronizeView,
        name: 'sso:account_sync',
        props: (route) => ({
          signature: route.query.signature,
          expires: route.query.expires,
          platformUUID: route.query.platformUUID,
          userInfos: route.query.userInfos,
          basedOn: route.query.basedOn,
          cryptedPart: route.query.cryptedPart,
        }),
        meta: {
          requiresAuth: false,
          title: 'Synchronisation de compte',
          breadCrumb: 'Synchronisation de compte',
          isSignup: true,
          hideHeader: true,
        },
      },
      {
        path: 'sso/connect/account',
        component: SSOAccountBindingView,
        meta: {
          requiresAuth: false,
        },
        children: [
          {
            path: 'options',
            component: SSOPairingModeSelection,
            name: 'sso:pairing_mode_selection',
            meta: {
              title: 'Avez vous un compte fidélité ?',
            },
          },
          {
            path: 'existing',
            component: SSOBindExistingAccountForm,
            name: 'sso:bind_existing_account',
            meta: {
              title: 'Association de compte',
            },
            props(route) {
              return {
                userInfos: route.query.userInfos,
                externalAccountId: route.query.externalAccountId,
              };
            },
          },
        ],
      },
      {
        // ScalePoints
        path: 'scalePoints',
        name: 'scale-points-public',
        component: ScalePoints,
        meta: {
          requiresAuth: false,
          title: 'Scale points',
          key: 'scalePoints',
          breadCrumb: 'Barème des points',
          public: true,
        },
      },
      {
        // todo: fix webViewIsActive. L'info doit correpondre à la config en base de données.
        // Shop
        path: webViewIsActive ? '/shop-webview' : '/shop',
        meta: {
          requiresAuth: false,
          isShop: true,
          title: 'Shop',
          key: 'disconnectedShop',
          public: true,
          breadCrumb: 'Boutique',
        },
        component: {
          render(c) {
            return c('router-view', {});
          },
        },
        children: [
          {
            path: '',
            component: Shop,
            name: 'shop',
            props(route) {
              const eventQuery = route.query.events || '';
              return {
                searchEvent: eventQuery === '' ? [] : eventQuery.split(',').map((a) => parseInt(a, 10)),
              };
            },
            meta: {
              requiresAuth: false,
              title: 'Shop',
              key: 'disconectedShop',
              public: true,
              breadCrumb: 'Boutique',
            },
          },
          {
            path: 'cashbackError',
            name: 'cashbackError',
            component: CashbackError,
            props: (route) => ({ alreadyConsumed: route.query.alreadyConsumed === 'true' }),
            meta: {
              requiresAuth: false,
              title: 'Cashback erreur',
              dynamicBreadCrumb: 'cashback erreur',
            },
          },
          {
            // OfferDetails
            path: ':offerId',
            name: 'offerDetail',
            component: OfferDetails,
            props: (route) => ({ offerId: Number.parseInt(route.params.offerId, 10) }),
            meta: {
              requiresAuth: false,
              title: 'Offer detail',
              dynamicBreadCrumb: 'offer',
            },
          },
          {
            path: 'cashbackError',
            name: 'cashbackError',
            component: CashbackError,
            props: (route) => ({
              alreadyConsumed: route.query.alreadyConsumed,
              offerState: route.query.offerState,
            }),
            meta: {
              requiresAuth: false,
              title: 'Cashback erreur',
              dynamicBreadCrumb: 'cashback erreur',
            },
          },
        ],
      },
      {
        // Ask for help
        path: 'help',
        name: 'help-public',
        component: Help,
        meta: {
          requiresAuth: false,
          title: 'Help',
          breadCrumb: "Besoin d'aide",
          public: true,
        },
      },
      {
        // GCU
        path: '/gcu',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: 'legal-notice',
            name: 'gcu-legal-notice',
            component: LegalNotice,
            meta: {
              requiresAuth: false,
              title: 'GCU - Mention légales',
              breadCrumb: 'Mention légales',
              public: true,
            },
          },
          {
            path: 'user-route',
            name: 'gcu-user-route',
            component: UserRoute,
            meta: {
              requiresAuth: false,
              title: 'GCU - Parcours utilisateurs',
              breadCrumb: 'Parcours utilisateurs',
              public: true,
            },
          },
          {
            path: 'rgpd',
            name: 'gcu-rgpd',
            component: RGPDContent,
            meta: {
              requiresAuth: false,
              title: 'GCU - Données personnelles - RGPD',
              breadCrumb: 'Données personnelles - RGPD',
              public: true,
            },
          },
          {
            path: 'rgpd-ecomotive-app',
            name: 'gcu-rgpd-ecomotive-app',
            component: RGPDEcomotiveApp,
            meta: {
              requiresAuth: false,
              title: 'GCU - Données personnelles - RGPD',
              breadCrumb: 'Données personnelles - RGPD',
              public: true,
            },
          },
          {
            path: 'cookies-policy',
            name: 'gcu-cookies-policy',
            component: CookiesPolicy,
            meta: {
              requiresAuth: false,
              title: 'GCU - Politique des cookies',
              breadCrumb: 'Politique des cookies',
              public: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      requiresAuth: false,
      title: 'Sign up',
      key: 'signUp',
      breadCrumb: 'Inscription',
      public: true,
    },
  },
  {
    path: '/emailConfirmation/:token',
    component: EmailConfirmation,
    props: (route) => ({ confirmationToken: route.params.token, fromAdmin: route.query.fromAdmin }),
    meta: {
      requiresAuth: false,
      title: 'Email confirmation',
      breadCrumb: "Confirmation de l'email",
    },
  },
  {
    // Calendrier
    path: '/calendar',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { title: 'Calendrier', breadCrumb: 'Calendrier' },
    children: [
      {
        path: '',
        name: 'calendar',
        component: CalendarView,
        meta: { title: 'Calendrier', breadCrumb: 'Calendrier' },
      },
    ],
  },
  {
    path: '/responsive/test',
    name: 'ResponsiveTest',
    component: ResponsiveTest,
    meta: {
      requiresAuth: false,
      title: 'Responsive test',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      title: 'Reset password',
      breadCrumb: 'Mot de passe oublié',
    },
  },
  {
    path: '/auth/reset-password/expired-token',
    name: 'expired-token',
    component: ExpiredToken,
    meta: {
      requiresAuth: false,
      title: 'Expired token',
      breadCrumb: 'Mot de passe oublié - Token Expiré',
    },
  },
  {
    path: 'oops',
    name: PageName.ERROR,
    component: Error,
    props: (route) => ({ reason: route.query.error }),
    meta: {
      requiresAuth: false,
      title: 'Oops ! Une erreur est survenue',
      // Permet d'éviter une boucle de redirection vers le shop/shop-webview dans certains cas
      // (notamment le SSO)
      isStaticPage: true,
      hideHeader: true,
    },
  },
  {
    // Stateless authorization with support for dynamic redirection
    path: '/auth/authorize',
    name: 'stateless-authorization',
    // This guard is used here instead and not in the global guards
    // to avoid unnecessary check on each route change (i.e. beforeEach)
    beforeEnter: statelessAuthorizationGuard,
    meta: {
      requiresAuth: false,
    },
  },
];
