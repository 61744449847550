var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.offer.id !== -1
        ? _c(
            "div",
            [
              _vm.step !== _vm.CONFIRMED_STEP && _vm.isMobile
                ? _c("BackNavigation", {
                    attrs: { title: _vm.backNavigationTitle }
                  })
                : _vm._e(),
              _vm.step === _vm.DETAIL_STEP
                ? [
                    _vm.isMobile
                      ? _c(
                          "div",
                          [
                            _c("OfferDetailCard", {
                              attrs: { offer: _vm.offer },
                              on: {
                                click: _vm.onBuyClick,
                                showSignin: _vm.showSignin
                              }
                            })
                          ],
                          1
                        )
                      : _c("OfferDetailsWeb", {
                          attrs: { offer: _vm.offer },
                          on: {
                            click: _vm.onBuyClick,
                            showSignin: _vm.showSignin
                          }
                        })
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.step !== _vm.DETAIL_STEP
        ? _c("PageTitle", { attrs: { text: "Boutique" } })
        : _vm._e(),
      _vm.step !== _vm.DETAIL_STEP
        ? _c(
            "div",
            { staticClass: "dialog" },
            [
              _c(
                "div",
                { staticClass: "dialog-background" },
                [_vm.compShowStickers ? _c("SvgHomeBackground") : _vm._e()],
                1
              ),
              _vm.step === _vm.REQUEST_CONFIRMATION_STEP_ADDRESS_VALIDATION
                ? _c("AddressConfirmationCard", {
                    attrs: { "order-address": _vm.orderAddress },
                    on: { "address-confirmed": _vm.handleAddressConfirmed }
                  })
                : _vm._e(),
              _vm.step === _vm.REQUEST_CONFIRMATION_STEP
                ? _c("RequestConfirmationCard", {
                    attrs: {
                      offer: _vm.offer,
                      "order-address": _vm.orderAddress,
                      companyName: _vm.offer.companyName,
                      companyAvatar: _vm.offer.companyAvatar,
                      title: _vm.offer.title,
                      points: _vm.offer.points,
                      currentUserPoints: _vm.currentUserPoints
                    },
                    on: { order: _vm.getOrder, click: _vm.onConfirmClick }
                  })
                : _vm._e(),
              _vm.step === _vm.CONFIRMED_STEP
                ? _c("OrderConfirmationCard", {
                    attrs: {
                      offer: _vm.offer,
                      order: _vm.order,
                      pointsBefore: _vm.pointsBefore,
                      pointsAfter: _vm.pointsAfter
                    }
                  })
                : _vm._e(),
              _vm.step === _vm.NOT_AVAILABLE_STEP
                ? _c("OfferNotAvailableCard")
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("ErrorDialog", {
        attrs: { message: _vm.dialogMessage, title: _vm.dialogTitle },
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }