















































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import PageTitle from '@/components/PageTitle.vue';
import PointsHistory from '@/components/Account/PointsHistory.vue';
import DashboardProfileCard from '@/components/Dashboard/DashboardProfileCard.vue';
import DashboardReferFriend from '@/components/Dashboard/DashboardReferFriend.vue';
import DashboardLastOrder from '@/components/Dashboard/DashboardLastOrder.vue';
import DashboardHintOffer from '@/components/Dashboard/DashboardHintOffer.vue';
import DashboardQuestion from '@/components/Dashboard/DashboardQuestion.vue';
import DashboardQuestionWeb from '@/components/Dashboard/DashboardQuestionWeb.vue';
import QuestionDialog from '@/components/Dialogs/QuestionDialog.vue';
import * as UserService from '@/services/userSpace/UserService';
import { Answer, Question, User } from '@/services/userSpace/types';

@Component({
  components: {
    PageTitle,
    PointsHistory,
    DashboardProfileCard,
    DashboardReferFriend,
    DashboardLastOrder,
    DashboardHintOffer,
    DashboardQuestion,
    DashboardQuestionWeb,
    QuestionDialog,
  },
  computed: { ...mapGetters('platform', ['webViewIsActive']) },
})
export default class Dashboard extends Vue {
  webViewIsActive!: boolean;

  loading: boolean = false;

  user: User = {
    id: -1,
    email: '',
    password: '',
    externalAccountId: '',
    firstname: '',
    lastname: '',
    userServices: [],
    emailConfirmation: true,
    referralCode: '',
  };

  question: Question = {
    id: -1,
    name: '',
    questionTypeId: -1,
    answers: [],
  };

  showQuestionDialog: boolean = false;

  rewardQuestionPoints: number = -1;

  questionDialogStepper: number = 1;

  forceBadPatternBalanceRefresh: number = Math.random();

  error: string = '';

  async created() {
    this.loading = true;
    this.user = await UserService.getCurrentUser();
    const questionToLoad = await UserService.getPublishedQuestion();
    if (questionToLoad && questionToLoad.id) {
      this.question = questionToLoad;
    }
    this.loading = false;
  }

  get isQuestionPublished() {
    return this.question.id !== -1;
  }

  get firstName() {
    return this.user.firstname ? this.user.firstname : '';
  }

  get referralCode() {
    if (this.user) {
      return this.user.referralCode;
    }
    return '';
  }

  get lastName() {
    return this.user.lastname ? this.user.lastname : '';
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get compInitials() {
    const firstNameInitial = this.user.firstname ? this.user.firstname[0] : '';
    const lastNameInitial = this.user.lastname ? this.user.lastname[0] : '';
    return `${firstNameInitial}${lastNameInitial}`;
  }

  get compDisplayedName() {
    const firstname = this.user.firstname ? this.user.firstname : '';
    const lastname = this.user.lastname ? this.user.lastname : '';
    const bothName = firstname.concat(' ').concat(lastname);

    if (firstname.length > 12) {
      return firstname.substring(0, 12).concat('...');
    }

    if (bothName.length > 10) {
      return firstname.concat(' ').concat(lastname[0]).concat('.');
    }

    return bothName;
  }

  get pointsLimit() {
    return this.isMobile ? 3 : 7;
  }

  get profileCardData() {
    return {
      fullName: this.compDisplayedName,
      initials: this.compInitials,
      buttonText: 'Voir mon profil',
      routeName: 'profile',
      avatar: !this.isMobile,
    };
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get activeFunctionalities() {
    return this.$store.getters['platform/getFunctionalities'].filter(({ isActive }) => isActive);
  }

  getFunctionality(slug: string) {
    return this.activeFunctionalities.filter((f) => f.functionality.slug === slug);
  }

  async openQuestionDialog() {
    if (this.isMobile) {
      window.scrollTo(0, 0);
    }
    this.showQuestionDialog = true;
  }

  async closeQuestionDialog() {
    this.showQuestionDialog = false;
  }

  async confirmQuestionDialog(answer: Answer) {
    const answerToSend = {
      questionId: this.question.id,
      id: answer.id ? answer.id : undefined,
      name: answer.name,
    };

    // send answer to api
    try {
      this.rewardQuestionPoints = await UserService.answerQuestion(answerToSend);
    } catch (e) {
      if (e instanceof UserService.AnswerNotPublishedOrAlreadyAnswered) {
        delete this.question;
        this.error = "Cette question n'est plus disponible ou vous y avez déjà répondu";
      } else {
        throw e;
      }
    }

    // if api return points, then it processed it right and we inform the user
    if (this.rewardQuestionPoints !== -1) {
      this.questionDialogStepper = 2;
      this.forceBadPatternBalanceRefresh = Math.random();
    }

    // reset question to make it disapear.
    this.question = {
      id: -1,
      name: '',
      questionTypeId: -1,
      answers: [],
    };
  }
}
