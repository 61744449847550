


































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPopup from '@/components/Svg/SvgPopup.vue';
import OfferCard from '@/components/Card/OfferCard.vue';
import { Offer } from '@/services/merchantSpace/types';
import joinPath from '@/utils/joinPath';

@Component({
  components: {
    SvgPopup,
    OfferCard,
  },
})
export default class CalendarOpenDay extends Vue {
  @Prop({ type: Object, required: true }) offer!: Offer;

  onOfferCardClick(offerId: number): void {
    this.$router.push({
      name: 'offerDetail',
      params: {
        offerId: offerId.toString()
      }
    });
  }

  get previewPhotoUrl() {
    if(!this.offer.previewPhoto) {
      return '';
    }

    return joinPath(process.env.VUE_APP_API_URL, 'api/files/offer', this.offer.previewPhoto.uuid);
  }

}
