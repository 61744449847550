
























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ErrorDialog extends Vue {
  showDialog: boolean = false;

  @Prop({
    type: String,
    default: 'Oups !',
    required: false
  })
  readonly title!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  readonly value!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  readonly message!: string;

  closeDialog() {
    this.show = false;
  }

  get show() {
    return this.value;
  }

  set show(value) {
    this.$emit('input', value);
  }
}
