import { apiAuthenticatedInstance, apiPublicInstance } from '../../services/API';
import { LoginResponse, SynchroResponse } from '../../services/userSpace/types';

export interface RegisterDTO {
  platformUUID: string;
  userInfos: string;
  metas: {
    externalAccountId: string;
    externalAccessToken: string;
    relatedTicketings?: ('MTICKET' | 'SVD')[];
  };
}

interface SignInCodeFlowDTO {
  platformUUID: string;
  state: string;
  code: string;
  codeVerifier?: string;
  clientId?: string;
  redirectUri: string;
}

export const isLoginResponse = (arg: RegisterDTO | LoginResponse): arg is LoginResponse => {
  return (arg as LoginResponse).tokens !== undefined;
};

export const signInCodeFlow = async ({
  platformUUID,
  state,
  code,
  codeVerifier,
  redirectUri,
  clientId,
}: SignInCodeFlowDTO): Promise<RegisterDTO | LoginResponse | SynchroResponse> => {
  return apiPublicInstance()
    .post(`/api/sso/users`, {
      platformUUID,
      state,
      code,
      codeVerifier,
      redirectUri,
      clientId,
    })
    .then((res) => res.data);
};

interface SignInPasswordFlowDTO {
  platformUUID: string;
  email: string;
  password: string;
}

export const signInPasswordFlow = async ({ platformUUID, email, password }: SignInPasswordFlowDTO) => {
  return apiPublicInstance()
    .post(`/api/sso/users`, {
      platformUUID,
      email,
      password,
    })
    .then((res) => res.data);
};

export const createUser = async ({
  platformUUID,
  userInfos,
  newsletter,
  cguValidated,
  dateOfBirth,
  referrerCode,
  services,
  externalAccessToken,
}: {
  platformUUID: string;
  userInfos: string;
  newsletter: boolean;
  cguValidated: boolean;
  dateOfBirth?: string;
  referrerCode?: string;
  services: { serviceId: number; externalAccountId?: string }[];
  externalAccessToken?: string;
}): Promise<LoginResponse> => {
  return apiPublicInstance()
    .post(`/api/sso/users/create`, {
      services,
      platformUUID,
      userInfos,
      newsletter,
      cguValidated,
      dateOfBirth,
      referrerCode,
      externalAccessToken,
    })
    .then((res) => res.data);
};

export function bindExistingAccount({
  currentEmail,
  dateOfBirth,
  userInfos,
  platformUUID,
}: {
  currentEmail: string;
  dateOfBirth: string;
  userInfos: string;
  platformUUID: string;
}) {
  return apiPublicInstance()
    .post(`/api/sso/bind_account`, {
      currentEmail,
      dateOfBirth,
      userInfos,
      platformUUID,
    })
    .then((res) => res.data);
}

export function encryptEmailBeforeAssign({ email, platformUUID, date }: { email: string; platformUUID: string; date: Date }) {
  return apiAuthenticatedInstance()
    .post(`/api/users/encrypt-before-assign`, {
      email,
      platformUUID,
      date,
    })
    .then((res) => res.data);
}

export function decryptEmailBeforeAssign({ encrypted, platformUUID }: { encrypted: string; platformUUID: string }) {
  return apiPublicInstance()
    .post(`/api/users/decrypt-before-assign`, {
      encrypted,
      platformUUID,
    })
    .then((res) => res.data);
}
