









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgPictoLuck from '@/components/Svg/SvgPictoLuck.vue';
import SvgPictoCashback from '@/components/Svg/SvgPictoCashback.vue';
import SvgPictoPro from '@/components/Svg/SvgPictoPro.vue';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';

import * as OfferService from '@/services/merchantSpace/OfferService';
import SvgPictoShowcase from '@/components/Svg/SvgPictoShowcase.vue';
import SvgPictoCollect from '@/components/Svg/SvgPictoCollect.vue';

@Component({
  components: {
    SvgPictoShowcase,
    SvgPictoLuck,
    SvgPictoPro,
    OfferAvatar,
    SvgPictoCollect,
    SvgPictoCashback,
  },
})
export default class OfferInfo extends Vue {
  textPts = '';

  textPoints = '';

  @Prop({
    type: String,
    required: true,
  })
  readonly companyName!: string;

  @Prop({
    type: String,
    required: true,
  })
  readonly title!: string;

  @Prop({
    type: Number,
    required: true,
  })
  readonly points!: number;

  @Prop({
    type: Number,
    required: true,
    default: 0,
  })
  readonly filledPoints!: number;

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  readonly maxPoints!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly typeId!: number;

  @Prop({
    type: Number,
    required: true,
  })
  readonly targetAudienceId!: number;

  pictoPro: string = require('@/assets/picto/pro.svg');

  pictoLuck: string = require('@/assets/picto/lucky.svg');

  get compTargetAudience(): string | null {
    return OfferService.isPublicOffer(this.targetAudienceId) ? null : this.pictoPro;
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.typeId);
  }

  get isCashbackOffer(): boolean {
    return OfferService.isCashbackOffer(this.typeId);
  }

  get isShowcaseOffer(): boolean {
    return OfferService.isShowcaseOffer(this.typeId);
  }

  get isCollectOffer(): boolean {
    return OfferService.isFundingOffer(this.typeId);
  }

  get textPoint() {
    if (this.points < 1) {
      this.textPoints = 'point';
    } else {
      this.textPoints = 'points';
    }
    return this.textPoints;
  }

  get textPt() {
    if (this.filledPoints < 1) {
      this.textPts = 'pt';
    } else {
      this.textPts = 'pts';
    }
    return this.textPts;
  }

  get progressBarValue() {
    return (100 * this.filledPoints) / this.maxPoints;
  }
}
