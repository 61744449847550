
































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import CarouselCard from '@/components/Card/OfferDetailCard/CarouselCard.vue';
import OfferAvatar from '@/components/Offer/OfferAvatar.vue';
import Countdown from '@/components/Offer/Countdown.vue';
import OrdersNumber from '@/components/Offer/OrdersNumber.vue';
import * as OfferService from '@/services/merchantSpace/OfferService';
import * as common from '@/services/common/common';
import SvgBackgroundShop from '@/components/Svg/SvgBackgroundShop.vue';
import SvgPictoLuck from '@/components/Svg/SvgPictoLuck.vue';
import SvgPictoCashback from '@/components/Svg/SvgPictoCashback.vue';

import SvgPictoPro from '@/components/Svg/SvgPictoPro.vue';
import SvgPictoShowcase from '@/components/Svg/SvgPictoShowcase.vue';
import SvgPictoCollect from '@/components/Svg/SvgPictoCollect.vue';
import { Offer } from '@/services/merchantSpace/types';
import { formatNumber } from '@/i18n';

const moment = require('moment');

moment.locale('fr');

@Component({
  components: {
    OfferAvatar,
    Countdown,
    OrdersNumber,
    PageTitle,
    CarouselCard,
    SvgBackgroundShop,
    SvgPictoLuck,
    SvgPictoPro,
    SvgPictoShowcase,
    SvgPictoCollect,
    SvgPictoCashback,
  },
})
export default class OfferDetails extends Vue {
  textMaxPoints = '';
  
  textPts = '';

  textPoints = '';

  objectifPoints: number = 0;

  acquiredPoints: number = 0;

  @Prop({ required: true })
  readonly offer!: Offer;

  get compShowStickers() {
    return !this.$store.getters['platform/getPlatform'].style.hideStickers;
  }

  get compPreviewsPhoto() {
    const photos = this.offer.previewPhotoUrl ? [`${process.env.VUE_APP_API_URL}/api${this.offer.previewPhotoUrl}`] : [];

    this.offer.photoUrls.forEach((pic) => {
      photos.push(`${process.env.VUE_APP_API_URL}/api${pic}`);
    });
    return photos;
  }

  get compCompanyAvatar() {
    return this.offer.companyAvatar ? `${process.env.VUE_APP_API_URL}/api${this.offer.companyAvatar}` : '';
  }

  get isConnected(): boolean {
    return common.isConnected();
  }

  get compTargetAudience(): string | null {
    return OfferService.isPublicOffer(this.offer.targetAudienceId) ? null : 'aze';
  }

  get isStandardOffer(): boolean {
    return OfferService.isStandardOffer(this.offer.offerTypeId);
  }

  get isLuckOffer(): boolean {
    return OfferService.isLuckOffer(this.offer.offerTypeId);
  }

  get isCashbackOffer(): boolean {
    return OfferService.isCashbackOffer(this.offer.offerTypeId);
  }

  get isShowcaseOffer(): boolean {
    return OfferService.isShowcaseOffer(this.offer.offerTypeId);
  }

  get isFundingOffer(): boolean {
    return OfferService.isFundingOffer(this.offer.offerTypeId);
  }

  get compTextForBtn(): string {
    let textBtn = '';
    switch (this.offer.offerTypeId) {
      case 1:
        textBtn = 'Acheter';
        break;
      case 2:
        textBtn = 'Participer';
        break;
      case 3:
        textBtn = 'Retour à la boutique';
        break;
      case 4:
        textBtn = 'Contribuer';
        break;
      case 5:
        textBtn = 'Acheter';
        break;
      default:
        textBtn = '';
    }
    return textBtn;
  }

  get textPoint() {
    if (this.offer.points < 1) {
      this.textPoints = 'point';
    } else {
      this.textPoints = 'points';
    }
    return this.textPoints;
  }

  get textPt() {
    if (!this.offer.filledPoints) {
      this.textPts = 'pt';
    } else {
      this.textPts = 'pts';
    }
    return this.textPts;
  }

  async onBuyClick() {
    if (this.isShowcaseOffer) {
      this.$router.push(`/shop`);
    } else {
      this.$emit('click');
    }
  }

  showLogin(): void {
    this.$store.dispatch('showLogin', true);
  }

  get compUrlWebsite(): string | null {
    if (this.offer.site) {
      const url = this.offer.site.replace(/^(?:https?:\/\/)?(?:http?:\/\/)?/i, '');
      return url;
    }
    return null;
  }

  get compTextForDate(): string {
    let text = '';

    if (
      OfferService.isStandardOffer(this.offer.offerTypeId) ||
      OfferService.isFundingOffer(this.offer.offerTypeId) ||
      OfferService.isCashbackOffer(this.offer.offerTypeId)
    ) {
      if (this.offer.expiredAt) {
        let compStartedDate = '';
        const compExpiredDate = moment(this.offer.expiredAt).format('D MMMM YYYY');
        if (moment(this.offer.startedAt).month() === moment(this.offer.expiredAt).month()) {
          compStartedDate = moment(this.offer.startedAt).format('D');
        } else {
          compStartedDate = moment(this.offer.startedAt).format('D MMMM');
        }
        text = `Valable du ${compStartedDate} au ${compExpiredDate}`;
      } else {
        text = `Offre permanente`;
      }
    } else {
      const date = moment(this.offer.drawAt).format('D MMMM YYYY');
      text = `Tirage au sort le ${date}`;
    }

    return text;
  }

  get compTextForStock(): string {
    let text = '';
    if (this.offer.currentStock) {
      text = this.offer.currentStock > 1 ? `${this.offer.currentStock} articles disponibles` : `${this.offer.currentStock} article disponible`;
    }
    return text;
  }

  get compTextForDelivery(): string {
    let text = '';
    if (this.offer.deliveryMethodId === 1) {
      text = 'Envoi par e-mail';
    } else if (this.offer.deliveryMethodId === 3) {
      text = 'Envoi postal';
    } else {
      let date;
      if (this.offer.collectionDateOption === 1) {
        date = moment(this.offer.startedAt).format('D MMMM');
      } else if (this.offer.collectionDateOption === 2) {
        date = moment(this.offer.collectionDateUnique).format('D MMMM');
      } else {
        date = moment(this.offer.collectionDateMin).format('D MMMM');
      }
      text = `Retrait sur place à partir du ${date}`;
    }
    return text;
  }

  get compIconForDelivery(): string {
    if (this.offer.deliveryMethodId === 1) {
      return 'fas fa-paper-plane';
    }
    if (this.offer.deliveryMethodId === 3) {
      return 'fas fa-envelope';
    }
    return 'fas fa-store';
  }

  get companyInitials() {
    return this.offer.companyName.charAt(0).toUpperCase();
  }

  get progressBarValue() {
    if (this.offer.filledPoints && this.offer.maxPoints) {
      return (100 * this.offer.filledPoints) / this.offer.maxPoints;
    }
    return 0;
  }

  get isLuck() {
    return this.offer.offerTypeId === OfferService.offerTypeEnum.LUCK.id;
  }

  get platformId() {
    return this.$store.getters['platform/getPlatform'].id;
  }
}
