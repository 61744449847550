
















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class BackNavigation extends Vue {
  @Prop({ type: String, default: '', required: true })
  title?: string;

  backNavigation() {
    this.$router.back();
  }
}
