var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      key: _vm.$store.getters.loginVisible,
      staticClass: "app",
      class: [_vm.isErrorPage]
    },
    [
      _c("NotificationApp"),
      _c("QrcodeScanManager"),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : _vm.showApiError
        ? _c("Error")
        : [
            _vm.showHeader
              ? _c("Header", {
                  staticClass: "app-header",
                  attrs: {
                    showLogoOrPreviousBtn: !_vm.webViewIsActive,
                    showLogoutBtn: !_vm.webViewIsActive,
                    logo: _vm.platform.style.logoUrl
                  },
                  on: {
                    closeSignin: _vm.closeDialog,
                    "is-logout": _vm.redirectToLogout
                  }
                })
              : _vm._e(),
            !_vm.isCalendarRoute && _vm.calendar && _vm.isConnected
              ? _c("CalendarBanner", {
                  attrs: { calendarBanner: _vm.calendar.calendarBanner }
                })
              : _vm._e(),
            _c("Banner"),
            _c(
              "v-main",
              {
                staticClass: "app-content",
                class: [_vm.showAlertMail ? "nopadding" : ""]
              },
              [
                _c(
                  "v-container",
                  { staticClass: "pa-0", attrs: { fluid: "" } },
                  [
                    !_vm.webViewIsActive && _vm.breadCrumb
                      ? _c(
                          "div",
                          { staticClass: "breadcrumb" },
                          [
                            _vm.$vuetify.breakpoint.smAndUp &&
                            _vm.breadCrumb.length > 1
                              ? _c("v-breadcrumbs", {
                                  staticClass: "breadcrumb",
                                  attrs: { items: _vm.breadCrumb },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("mdi-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "v-breadcrumbs-item",
                                              {
                                                attrs: {
                                                  disabled: item.disabled,
                                                  exact: true,
                                                  to: item.to
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2716708232
                                  )
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("router-view", { staticClass: "app-content-view" }),
                    _vm.showNewCguButton
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "app-content-newcgu_btn",
                            attrs: { color: "homeglobal_fond_bouton" },
                            on: { click: _vm.signNewCgu }
                          },
                          [_vm._v(" Accepter les CGU ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm.showFooter
              ? _c("Footer", { staticClass: "app-footer" })
              : _vm._e(),
            _vm.displayLogoutMessage && _vm.messages
              ? _c("LogoutMessage", {
                  attrs: {
                    description: _vm.messages.logoutMessageBlockDescription,
                    title: _vm.messages.logoutMessageBlockTitle,
                    value: _vm.displayLogoutMessage
                  },
                  on: { input: _vm.closeLogoutMessage }
                })
              : _vm._e(),
            _vm.showShopOfflineMessage && _vm.messages
              ? _c("ShopOfflineMessage", {
                  attrs: {
                    description: _vm.messages.shopOfflineMessageDescription,
                    title: _vm.messages.shopOfflineMessageTitle
                  },
                  on: {
                    input: function($event) {
                      _vm.showShopOfflineMessage = false
                    }
                  }
                })
              : _vm._e(),
            _vm.displayMigrationMessage
              ? _c("MigrationMessage", {
                  on: { input: _vm.closeMigrationMessage }
                })
              : _vm._e(),
            _vm.$store.getters.loginVisible
              ? _c("Signin", {
                  on: {
                    input: _vm.closeDialog,
                    input2: _vm.showMigrationMessage,
                    onDeactivated: function(ref) {
                      var user = ref.user

                      return _vm.showReactivation(user)
                    },
                    "is-open": function($event) {
                      _vm.showForgotPasswordDialog = true
                    }
                  }
                })
              : _vm._e(),
            _vm.$store.getters.ssoSignUpVisible
              ? _c("SSOSignup", { on: { close: _vm.closeSSOSignupDialog } })
              : _vm._e(),
            _vm.showForgotPasswordDialog
              ? _c("ForgotPassword", {
                  model: {
                    value: _vm.showForgotPasswordDialog,
                    callback: function($$v) {
                      _vm.showForgotPasswordDialog = $$v
                    },
                    expression: "showForgotPasswordDialog"
                  }
                })
              : _vm._e(),
            _vm.showReactivateAccountDialog
              ? _c("ReactivateAccount", {
                  attrs: { numberOfDays: _vm.numberOfDaysUntilDeletion },
                  on: {
                    onClose: _vm.closeReactivateAccountDialog,
                    onReactivation: _vm.reactivateAccount
                  }
                })
              : _vm._e(),
            _vm.showNewCguDialog
              ? _c("NewCguDialog", {
                  attrs: { consent: _vm.newCgu },
                  on: { signNewCgu: _vm.signNewCgu },
                  model: {
                    value: _vm.showNewCguDialog,
                    callback: function($$v) {
                      _vm.showNewCguDialog = $$v
                    },
                    expression: "showNewCguDialog"
                  }
                })
              : _vm._e(),
            _vm.showSSOSynchroDialog
              ? _c("AskSSOSynchroDialog", {
                  on: {
                    acceptSynchro: _vm.acceptSSOSynchro,
                    declineSynchro: _vm.logout
                  }
                })
              : _vm._e(),
            _vm.showConfirmSynchroDialog
              ? _c("ConfirmSSOSynchroDialog", {
                  attrs: { platformKey: _vm.platform.key }
                })
              : _vm._e(),
            _vm.showFirstConnectSSO && !_vm.isExcludedRoute
              ? _c("ExternalCguAgreement", {
                  on: { signNewCgu: _vm.signNewCgu },
                  model: {
                    value: _vm.showFirstConnectSSO,
                    callback: function($$v) {
                      _vm.showFirstConnectSSO = $$v
                    },
                    expression: "showFirstConnectSSO"
                  }
                })
              : _vm._e()
          ],
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000, color: "error", top: "" },
          on: {
            input: function($event) {
              return _vm.hideError()
            }
          },
          model: {
            value: _vm.showError,
            callback: function($$v) {
              _vm.showError = $$v
            },
            expression: "showError"
          }
        },
        [
          _vm._v(
            " La requête a échouée. Vous pouvez renouveler votre demande ultérieurement. "
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }