var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "offer-info" }, [
    _c("div", { staticClass: "offer-info__title" }, [
      _c("span", { staticClass: "offer-info__title-text" }, [
        _vm._v(_vm._s(_vm.title))
      ])
    ]),
    _c(
      "div",
      { staticClass: "offer-info__points" },
      [
        _c("div", { staticClass: "offer-info__points-picto" }, [
          _vm.compTargetAudience
            ? _c(
                "div",
                { staticClass: "offer-info__points-picto-svg" },
                [_c("SvgPictoPro")],
                1
              )
            : _vm._e(),
          _vm.isLuckOffer
            ? _c(
                "div",
                { staticClass: "offer-info__points-picto-svg" },
                [_c("SvgPictoLuck")],
                1
              )
            : _vm._e(),
          _vm.isCashbackOffer
            ? _c(
                "div",
                { staticClass: "offer-info__points-picto-svg" },
                [_c("SvgPictoCashback")],
                1
              )
            : _vm._e(),
          _vm.isShowcaseOffer
            ? _c(
                "div",
                { staticClass: "offer-info__points-picto-svg" },
                [_c("SvgPictoShowcase")],
                1
              )
            : _vm._e(),
          _vm.isCollectOffer
            ? _c(
                "div",
                { staticClass: "card-offer-type__svg" },
                [_c("SvgPictoCollect")],
                1
              )
            : _vm._e()
        ]),
        _vm.isCollectOffer
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "offer-info__points-objectif" },
                  [
                    _vm._v(" Objectif : "),
                    _c("i18n-n", {
                      attrs: {
                        tag: "span",
                        value: _vm.maxPoints,
                        format:
                          _vm.maxPoints.toString().length <= 6
                            ? "decimal"
                            : "decimal_notation"
                      }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc("global.abbreviated_points", _vm.maxPoints)
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c("v-progress-linear", {
                  staticClass: "offer-info__progressBar",
                  attrs: {
                    rounded: "",
                    height: "1rem",
                    "background-color": "secondary lighten-5",
                    color: "secondary lighten-1",
                    value: _vm.progressBarValue
                  }
                }),
                _c(
                  "div",
                  { staticClass: "offer-info__points-acquired" },
                  [
                    _vm._v(" Déjà récolté : "),
                    _c("i18n-n", {
                      attrs: {
                        tag: "span",
                        value: _vm.filledPoints,
                        format:
                          _vm.filledPoints.toString().length <= 6
                            ? "decimal"
                            : "decimal_notation"
                      }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$tc("global.abbreviated_points", _vm.filledPoints)
                        ) +
                        " "
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.isShowcaseOffer & !_vm.isCollectOffer
          ? _c("span", { staticClass: "offer-info__points-text" }, [
              _vm._v(_vm._s(_vm.points) + " " + _vm._s(_vm.textPoint))
            ])
          : _vm._e(),
        _c("v-icon", { staticClass: "offer-info__points-share" }, [
          _vm._v("fas fa-share-alt-square")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }