


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Calendar } from '@/services/merchantSpace/CalendarService';
import { loadFontFace } from '@/utils/loadFontFace';

@Component({
  components: {}
})
export default class CalendarBanner extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  calendarBanner!: Calendar['calendarBanner']

  async created () {
    const { textFont } = this.style;
    const { textFont: buttonFont } = this.style.button;

    await Promise.all([
      textFont.url ? loadFontFace('TextBannerPreview', textFont.url) : undefined,
      buttonFont.url ? loadFontFace('ButtonBannerPreview', buttonFont.url) : undefined
    ]);
  }

  get style () {
    return this.calendarBanner.style;
  }

  get rootCSS () {
    const hasBackgroundUrl = !!this.style.backgroundUrl;

    return {
      color: this.style.textColor,
      ...hasBackgroundUrl && {
        'background-image': `url(${this.style.backgroundUrl})`
      },
      ...!hasBackgroundUrl && {
        'background-color': 'black'
      }
    }
  }
}

