import { get } from 'lodash-es';

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import fr from './fr';

Vue.use(VueI18n);

export function getTranslation(key) {
  return get(fr, key);
}

export function formatNumber(value: number, options: any = {}): string {
  return new Intl.NumberFormat('fr', options).format(value);
}

function loadDictionaryMessages() {
  const dictionaries = require.context('./dictionary', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  dictionaries.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = dictionaries(key);
    }
  });

  return messages;
}

const numberFormats = {
  fr: {
    decimal_notation: {
      notation: 'compact',
      compactDisplay: 'long',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 7,
    },
    decimal_notation_short: {
      notation: 'compact',
      compactDisplay: 'short',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 7,
    },
    decimal: {},
  },
};

function customPluralizationRule(points: number) {
  // this === VueI18n instance, so the locale property also exists here
  const haveMillion = points.toString().length > 6;

  // 0: zero, 1: one, 2: many
  if (points === 0) {
    return 0;
  }

  if (points === 1) {
    return 1;
  }

  // 1.000.000 de points
  if (haveMillion) {
    return 3;
  }

  return 2;
}

export default new VueI18n({
  locale: 'fr',
  messages: loadDictionaryMessages(),
  pluralizationRules: {
    fr: customPluralizationRule,
  },
  numberFormats,
});
