var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.calendar && _vm.calendar.style.backgroundUrl,
      class: "CalendarView calendar fade-in",
      style: _vm.rootCSS
    },
    [
      _vm.isLoading
        ? void 0
        : _vm.calendar
        ? _c(
            "div",
            { staticClass: "slide-in" },
            [
              !_vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-btn",
                    {
                      staticClass: "back-btn",
                      attrs: {
                        to: { name: "dashboard:index" },
                        text: "",
                        "x-large": "",
                        size: "x-large"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-chevron-left")
                      ]),
                      _vm._v(" Tableau de bord ")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "calendar__container",
                  class: {
                    "slide-out": _vm.animationState === "discovering",
                    "slide-in": _vm.animationState === "discovering-reverse"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "calendar__container-header",
                      style: { "--stagger-delay": "0.7s" }
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-h3 calendar__container-header-title mb-6",
                          style: {
                            color: _vm.calendar.style.titleColor
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.calendar.title) + " ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "calendar__container-header-description text-h6"
                        },
                        [_vm._v(" " + _vm._s(_vm.calendar.description) + " ")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "calendar__container-box",
                      style: { "--stagger-delay": "0.95s" }
                    },
                    _vm._l(_vm.giftList, function(ref, index) {
                      var isSelectionable = ref.isSelectionable
                      var calendarDay = ref.calendarDay
                      var positionId = ref.positionId
                      var lockLabel = ref.lockLabel
                      var isOpen = ref.isOpen
                      var isLoading = ref.isLoading
                      return _c(
                        "div",
                        { key: index, staticClass: "calendar__container-col" },
                        [
                          _c("CalendarCard", {
                            attrs: {
                              lockLabel: lockLabel,
                              isSelectionable: isSelectionable,
                              offer: calendarDay && calendarDay.offer,
                              isOpen: isOpen,
                              color: _vm.rootCSS.color,
                              loading: isLoading
                            },
                            on: {
                              open: function($event) {
                                return _vm.openGift(positionId, calendarDay)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "no-content-container" },
            [
              _vm.errorMessage
                ? _c(
                    "v-alert",
                    { attrs: { color: "red lighten-4", icon: "mdi-alert" } },
                    [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                  )
                : _c(
                    "v-alert",
                    {
                      attrs: {
                        color: "blue-grey",
                        icon: "mdi-information-outline",
                        dark: ""
                      }
                    },
                    [_vm._v(" Aucun calendrier en cours ")]
                  )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.showDiscoveredDialog,
            elevation: "0",
            "hide-overlay": "",
            "max-width": "40rem",
            transition: "scroll-y-reverse-transition",
            "content-class": "open-day-dialog"
          },
          on: {
            input: function($event) {
              return _vm.handleOpenDialogVisibility({ isVisible: $event })
            }
          }
        },
        [
          !!(_vm.discoveredDay && _vm.discoveredDay.offer)
            ? _c("CalendarOpenDay", {
                attrs: { offer: _vm.discoveredDay.offer },
                on: {
                  back: function($event) {
                    return _vm.handleOpenDialogVisibility({ isVisible: false })
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }