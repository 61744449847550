
























import { Vue, Component, Prop } from 'vue-property-decorator';
import Datatable from '@/components/Datatable.vue';

const moment = require('moment');

@Component({
  components: {
    Datatable,
  },
})

export default class OrdersProfile extends Vue {
  @Prop({ type: Array, required: true, }) criterias!: Object[];

  headers: Object[] = [
    { name: 'Label', width: '25%' },
    { name: 'Nombre de points', width: '14%' },
    { name: 'Jours', width: '33%' },
    { name: 'Horaires', width: '12%' },
    { name: 'Date', width: '16%' },
  ];

  formatedHours(hour): string {
    return moment(hour, ["HH:mm:ss"]).format('HH[h]mm');
  }

  formatedDate(date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  formatedDays(days): string {
   return days.map(day => moment().isoWeekday(day + 1).format('dddd')).join(', ');
  }
}
